import '../css/homemenu.css';

import LongShape from '../images/icons/longdecor.svg';
import { Link as RouterLink } from 'react-router-dom';

import React, { useState, useEffect } from "react";
import axios from '../../config/index';


function HomeMenu(){


    
    const [Products, setAllProducts] = useState([]);

    const getAllProducts = () => {
        axios.post("AllItems", {
            table: "products",
        })
        .then((res) => {
            if (res.data) {
                setAllProducts(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };

    useEffect(() => {
        getAllProducts();
    }, []);



    const currentPath = window.location.pathname;
    const isARLanguage = currentPath.includes('jp');
    const importARStyles = async () => {
        if (isARLanguage) {
        try {
            // Import the AR-specific style sheet
            const arStyles = await import('../css/Jp.css');
            arStyles.default(); // Apply the styles if needed
        } catch (error) {
            console.error('Error importing AR styles:', error);
        }
        }
    };

    React.useEffect(() => {
        importARStyles();
    }, [currentPath]);


    return(
        <>
            <section className='home-menu'>
                <img src={LongShape} alt="menu long shape"  />
                <div className='container'>
                    <div className='home-menu-content'>
                        <div className='row'>
                            <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                <div className='menu-eles'>
                                    <div className='header'>
                                        <h2> 最高のコレクション </h2>
                                        <h1> <span></span> <span></span> <span></span> 
                                                たこ焼き
                                            <span></span> <span></span> <span></span> 
                                        </h1>
                                    </div>

                                    
                                    {
                                    Products && Products.length > 0 && (

                                        Products.filter(product => product.place === 'Best Seller').map((product, index) => (
                                            <div key={index} className='ele'>
                                                <h3>{product.name_ch} <span>{product.price} . EGP</span></h3>
                                                <p>{product.para_ch}</p>
                                            </div>
                                        ))
                                    )
                                    }

                                
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                <div className='menu-eles'>
                                    <div className='header'>
                                        <h2> 当社の選りすぐり </h2>
                                        <h1> <span></span> <span></span> <span></span> 
                                                たこ焼き
                                            <span></span> <span></span> <span></span> 
                                        </h1>
                                    </div>

                                    {
                                    Products && Products.length > 0 && (

                                        Products.filter(product => product.place === 'Our Choice').map((product, index) => (
                                            <div key={index} className='ele'>
                                                <h3>{product.name_ch} <span>{product.price} . EGP</span></h3>
                                                <p>{product.para_ch}</p>
                                            </div>
                                        ))
                                    )
                                    }
                                    
                                    
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                <div className='menu-eles'>
                                    <div className='header'>
                                        <h2> 当社のおすすめ </h2>
                                        <h1> <span></span> <span></span> <span></span> 
                                                たこ焼き
                                            <span></span> <span></span> <span></span> 
                                        </h1>
                                    </div>

                                    {
                                    Products && Products.length > 0 && (

                                        Products.filter(product => product.place === 'Must Try').map((product, index) => (
                                            <div key={index} className='ele'>
                                                <h3>{product.name_ch} <span>{product.price} . EGP</span></h3>
                                                <p>{product.para_ch}</p>
                                            </div>
                                        ))
                                    )
                                    }

                                </div>
                            </div>

                            <div className='col-12'>
                                <div className='read-more'>
                                    <RouterLink to="/jp/our-menu"> メニューを見る </RouterLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>  
        </>
    )
}

export default HomeMenu;
