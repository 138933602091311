import './css/visitus.css';
import React, { useState } from 'react';

import LongDecoration from './images/icons/decorverylong.svg';
import VisitImg from './images/7.webp';
import VideoIcon from './images/icons/video.svg';
import LongDeco2 from './images/icons/decorlong.svg';

import VideoVisit from './videos/video.mp4';

import { useEffect,useRef  } from "react";
import axios from '../config/index';


function VisitUs(){


        
    const [location, setLocation] = useState([]);

    const [email_1, setemail_1] = useState([]);
    const [email_2, setemail_2] = useState([]);

    const [working_days_1, setworking_days_1] = useState([]);
    const [working_hours_1, setworking_hours_1] = useState([]);

    const [working_days_2, setworking_days_2] = useState([]);
    const [working_hours_2, setworking_hours_2] = useState([]);


    const getLocation = () => {
        let table = "social";
        let itemId = 1;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setLocation(res.data);
                }
            })
            .catch((err) => console.log(err));
    };


    
    const getEmail1 = () => {
        let table = "social";
        let itemId = 2;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setemail_1(res.data);
                }
            })
            .catch((err) => console.log(err));
    };

    const getEmail2 = () => {
        let table = "social";
        let itemId = 3;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setemail_2(res.data);
                }
            })
            .catch((err) => console.log(err));
    };



    const getWorkingDays1 = () => {
        let table = "social";
        let itemId = 4;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setworking_days_1(res.data);
                }
            })
            .catch((err) => console.log(err));
    };

    const getWorkingHours1 = () => {
        let table = "social";
        let itemId = 5;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setworking_hours_1(res.data);
                }
            })
            .catch((err) => console.log(err));
    };



    
    const getWorkingDays2 = () => {
        let table = "social";
        let itemId = 6;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setworking_days_2(res.data);
                }
            })
            .catch((err) => console.log(err));
    };

    const getWorkingHours2 = () => {
        let table = "social";
        let itemId = 7;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setworking_hours_2(res.data);
                }
            })
            .catch((err) => console.log(err));
    };





    useEffect(() => {
        getLocation();
        getEmail1();
        getEmail2();
        getWorkingDays1();
        getWorkingHours1();
        getWorkingDays2();
        getWorkingHours2();
    }, []);



    const [isVideoPlaying, setVideoPlaying] = useState(false);

    const handlePlayButtonClick = () => {
    setVideoPlaying(true);
    };

    const handleVideoClick = () => {
    setVideoPlaying(false);
    };


    return(
        <>
            <section className='visit-us'>
                <div className='container'>
                    <div className='visit-us-content'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <div className='visit-us-text'>
                                    <h2> How to find our restaurant </h2>
                                    <h1> <span></span> <span></span> <span></span> 
                                            VISIT US
                                        <span></span> <span></span> <span></span> 
                                    </h1>

                                    <a target='_blank' href="https://goo.gl/maps/22LHhRGoQmGn1RdX8">
                                        {location.link}
                                    </a>

                                    <div className='emails'>
                                        <a href={`mailto:${email_1.link}`}> {email_1.link} </a>
                                        <span> </span>
                                        <a href={`mailto:${email_2.link}`}> {email_2.link} </a>
                                    </div>

                                    <div className='open-hours'>
                                        <h4> OPENING HOURS: </h4>
                                        
                                        <h5> <span>{working_days_1.link} </span>  <span> {working_hours_1.link} </span> </h5>
                                        <h5> <span>{working_days_2.link} </span>  <span> {working_hours_2.link}</span> </h5>

                                    </div>

                                    <img src={LongDecoration} alt="long decoration image"/>
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <div className='visit-us-image'>
                                    {isVideoPlaying ? (
                                        <video
                                        autoPlay={isVideoPlaying}
                                        playsInline={isVideoPlaying}
                                        src={VideoVisit}
                                        alt='video takoyaki'
                                        onClick={handleVideoClick}
                                        ></video>
                                    ) : (
                                        <>
                                        <img
                                            className='video-img'
                                            src={VisitImg}
                                            alt='long decoration image'
                                        />
                                        <div className='overlay'></div>

                                        <button
                                            className='play-video'
                                            onClick={handlePlayButtonClick}
                                        >
                                            <img
                                            className='video-icon'
                                            src={VideoIcon}
                                            alt='videoIcon'
                                            />
                                        </button>

                                        <img
                                            className='video-dec'
                                            src={LongDeco2}
                                            alt='long decoration image'
                                        />
                                        </>
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default VisitUs;