import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';
import ShowButton from '../ShowButton';






function AddProduct(){


        
    useEffect(() => {
        axios
    .get('session')
    .then((res) => {
        if (res.data.valid) {

            if(res.data.type == "admin"){
            }else{
                navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);





    const navigate = useNavigate();


    
    const [para, setPara] = useState("");
    const [para_ar, setParaar] = useState("");
    const [para_ch, setparach] = useState("");
    
    const [name, setName] = useState("");
    const [name_ar, setNamear] = useState("");
    const [name_ch, setNamech] = useState("");

    const [price, setPrice] = useState("");
    const [place, setplace] = useState("");



    const [img, setImg] = useState("");


    const handleSubmit = (event) => {
        event.preventDefault();

        let Loading = document.querySelector(".loading-screen"); 
        Loading.classList.add("active");

        const data = new FormData();

                
        data.append("para", para || "");
        data.append("para_ar", para_ar || "");
        data.append("para_ch", para_ch || "");



        data.append("name", name || "");
        data.append("name_ar", name_ar || "");
        data.append("name_ch", name_ch || "");

        data.append("price", price || "");

        data.append("place", place || "");

        if (img) {
            data.append("img", img);
        }
    
    
        const config = {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        };
    
        axios
        .post("/add-product", data, config)
        .then((response) => {
            
            Loading.classList.remove("active");

            let alertS = document.querySelector(".alert-success"); 
            alertS.classList.add("active");


            setTimeout(() => {
                alertS.classList.remove("active");
            }, 3000);


        })
        .catch((error) => {
            
            Loading.classList.remove("active");

            let alertD = document.querySelector(".alert-danger"); 
            alertD.classList.add("active");
            setTimeout(() => {
                alertD.classList.remove("active");
            }, 3000);
            console.log(error);
        });
    };
    


    axios.defaults.withCredentials = true;



    const getIfLogin= ()=>{
        
    axios
    .get('session')
    .then((res) => {
        if (!res.data.valid) {
            navigate('/admin-login');

        }
        
    })
    .catch((err) => console.log(err));

    }




    useEffect(() => {
        getIfLogin();
    }, []);
    




    

    return(
        <>

                    <div className='loading-screen'>
                            <div class="loader">
                                <div class="outer"></div>
                                <div class="middle"></div>
                                <div class="inner"></div>
                            </div>

                        </div>
            <section className='dashboard'>



                <div className='edit-form'>

                    <div className='alert-success'>
                        <h1> Data Added successfully  <i class="las la-check-circle"></i></h1>
                    </div>

                    <div className='alert-danger'>
                        <h1> Failed To Add Data <i class="las la-exclamation-triangle"></i></h1>

                    </div>

                    <h1> Add Product</h1>

                    <form onSubmit={handleSubmit}>


                    
                    <div className='input'>
                        <label> Product Name  </label>
                        <input  onChange={(event) => setName(event.target.value)} type='text' required="required"  maxLength="200" name='name'/>
                    </div>

                    
                    <div className='input'>
                        <label>Product Name ( arabic )  </label>
                        <input  onChange={(event) => setNamear(event.target.value)} type='text' required="required"  maxLength="200" name='name_ar'/>
                    </div>


                    <div className='input'>
                        <label> Product Name ( japan ) </label>
                        <input  onChange={(event) => setNamech(event.target.value)} type='text' required="required"  maxLength="200" name='name_ch'/>
                    </div>




                    <div className='input'>
                        <label> Product Description  </label>
                        <textarea  onChange={(event) => setPara(event.target.value)} type='text' required="required"  maxLength="200" name='para'>
                            
                        </textarea>
                    </div>

                    
                    <div className='input'>
                        <label> Product Description ( arabic )  </label>
                        <textarea  onChange={(event) => setParaar(event.target.value)} type='text' required="required"  maxLength="200" name='para_ar'>
                            
                        </textarea>                    
                    </div>


                    <div className='input'>
                        <label> Product Description ( japan ) </label>
                        <textarea  onChange={(event) => setparach(event.target.value)} type='text' required="required"  maxLength="200" name='para_ch'>
                            
                        </textarea>
                    </div>



                    <div className='input'>
                        <label> Product Price  </label>
                        <input  onChange={(event) => setPrice(event.target.value)} type='text' required="required" name='price'/>
                    </div>

                    
                    <div className='input'>
                        <label> Product place  </label>
                        <select  onChange={(event) => setplace(event.target.value)}  required="required" name='place'>
                            <option value="">Choose place</option>
                            <option value="Best Seller">Best Seller</option>
                            <option value="Our Choice">Our Choice</option>
                            <option value="Must Try">Must Try</option>

                        </select>
                    </div>


                    <div className='input'>
                        <label> Product Image  </label>
                        <input onChange={(event) => setImg(event.target.files[0])} type='file' name="img" accept="image/*"/>
                    </div>

                
                    <button type='submit'> Save </button>

                    </form>
                </div>
            </section>
        
        </>
    )
}

export default AddProduct;