import './css/pageheader.css';

import headerdeco from './images/icons/decor.svg';


function PageHeader(props){
    return(
        <>
            <section className='page-header'>
                <div className='overlay'></div>

                <div className='container'>
                    <div className='page-header-content'>
                        <div>
                            <img src={headerdeco}  />
                            <h1> {props.header} </h1>
                            <img src={headerdeco}  />
                        </div>

                        <p>
                            {props.para}
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PageHeader;