import '../css/ad.css';

import AdImg from '../images/12.webp';
import React from 'react';
import { useEffect,useState  } from "react";
import axios from '../../config/index';
function Ad(){

    const [PhoneNumber, setPhonenumber] = useState([]);

    const getPhonenumber = () => {
        let table = "social";
        let itemId = 8;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setPhonenumber(res.data);
                }
            })
            .catch((err) => console.log(err));
    };


    useEffect(() => {
        getPhonenumber();
    }, []);

   
    const currentPath = window.location.pathname;
    const isARLanguage = currentPath.includes('jp');
    const importARStyles = async () => {
        if (isARLanguage) {
        try {
            // Import the AR-specific style sheet
            const arStyles = await import('../css/Jp.css');
            arStyles.default(); // Apply the styles if needed
        } catch (error) {
            console.error('Error importing AR styles:', error);
        }
        }
    };

    React.useEffect(() => {
        importARStyles();
    }, [currentPath]);


    return(
        <>
            <section className='ad'>
                <div className='container'>
                    <div className='ad-content-container'>
                        <div className='row'> 
                            <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                <div className='ad-content'>
                                    <h2>  たこ焼きセット販売中 </h2>
                                    <h1> <span></span> <span></span> <span></span> 
                                            弊社の特長
                                        <span></span> <span></span> <span></span> 
                                    </h1>

                                    <p>
                                        弊社を特別な存在にするのは、包括性への献身です。私たちはコミュニティの多様な嗜好を理解し尊重しています。そのため、当社のたこ焼きは美味しさだけでなく、最高のハラール基準にも準拠しています。味わい深いソースから濃厚なスープストックまで、すべての要素が慎重に選ばれ、誰にでも対応する愉快な体験を確保するよう心がけています。
                                    </p>

                                    <a href={`tel:${PhoneNumber.link}`}>
                                        お問い合わせ
                                    </a>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                <div className='ad-content'>
                                    <div className='overlay'></div>
                                    <img src={AdImg} alt="ad image" />
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                <div className='ad-content'>
                                    <h2>  最高のコレクション </h2>
                                    <h1> <span></span> <span></span> <span></span> 
                                            招待
                                        <span></span> <span></span> <span></span> 
                                    </h1>

                                    <p>
                                        私たちはエジプトの人々に、料理の旅に出て、たこ焼きの本物の味を堪能していただきたいと考えています。日本の料理が好きな方も、その魅力に初めて触れる方も、私たちは皆さんと日本の味を共有したいと思っています。Yamatakoを忘れられない日本のストリートフード体験の行き先としてお考えいただき、ありがとうございます。
                                    </p>

                                    <a href={`tel:${PhoneNumber.link}`}>
                                        お問い合わせ
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Ad;
