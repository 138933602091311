import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState, useEffect } from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';




function EditNumbers() {



    useEffect(() => {
        axios.get('session')
            .then((res) => {
                if (res.data.valid) {
                    if (res.data.type !== "admin") {
                        navigate('/admin-login');
                    }
                } else {
                    // Handle the case when session is not valid
                }
            })
            .catch((err) => console.log(err));
    }, []);




    const [formChanged, setFormChanged] = useState(false); // Track if the form has changed


    const handleInputChange = () => {
        setFormChanged(true); // Set the formChanged flag to true when any input changes
    };




    const navigate = useNavigate();
    const [AllNumbers, setAllNumbers] = useState({});
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');


    const [formInputs, setFormInputs] = useState({
        header: "",
        header_en: "",
        sec_header: "",
        sec_header_en: "",
        para: "",
        para_en: "",
        num_one: "",
        num_two: "",
        num_three: "",
        num_text_one: "",
        num_text_one_en: "",
        num_text_two: "",
        num_text_two_en: "",
        num_text_three: "",
        num_text_three_en: "",

    });
    
    // Use formInputs to set the initial value of inputs
    useEffect(() => {
        if (AllNumbers.id) {
        setFormInputs({
            id:id,
            header: AllNumbers.header,
            header_en: AllNumbers.header_en,
            sec_header: AllNumbers.sec_header,
            sec_header_en: AllNumbers.sec_header_en,
            para: AllNumbers.para,
            para_en: AllNumbers.para_en,
            num_one: AllNumbers.num_one,
            num_two: AllNumbers.num_two,
            num_three: AllNumbers.num_three,
            num_text_one: AllNumbers.num_text_one,
            num_text_one_en: AllNumbers.num_text_one_en,
            num_text_two: AllNumbers.num_text_two,
            num_text_two_en: AllNumbers.num_text_two_en,
            num_text_three: AllNumbers.num_text_three,
            num_text_three_en: AllNumbers.num_text_three_en,
        });
        }
    }, [AllNumbers]);

        const handleChange = (e) => {
            handleInputChange();
            const { name, value, type } = e.target;
            const inputValue = type === "file" ? e.target.files[0] : value;
        
            setFormInputs((prevFormInputs) => ({
            ...prevFormInputs,
            [name]: inputValue,
            }));
        };


        
        const handleSubmit = async (e) => {
            e.preventDefault();
        
            let Loading = document.querySelector(".loading-screen");
            Loading.classList.add("active");
        
            const {
                id,
                sec_header,
                sec_header_en,
                header,
                header_en,
                para,
                para_en,
                num_one,
                num_two,
                num_three,
                num_text_one,
                num_text_one_en,
                num_text_two,
                num_text_two_en,
                num_text_three,
                num_text_three_en,
            } = formInputs;
        
            if (
                !id ||
                !sec_header ||
                !sec_header_en ||
                !header ||
                !header_en ||
                !para ||
                !para_en ||
                !num_one ||
                !num_two ||
                !num_three ||
                !num_text_one ||
                !num_text_one_en ||
                !num_text_two ||
                !num_text_two_en ||
                !num_text_three ||
                !num_text_three_en
            ) {
                console.log(id,sec_header,sec_header_en,header,header_en,para,para_en,
                    num_one,num_two,num_three,num_text_one,num_text_one_en,num_text_two,num_text_two_en,
                    num_text_three,num_text_three_en)
                return alert("All fields are required");
            }
            
        
            try {
                const response = await axios.post("/update-number", {
                id,
                sec_header,
                sec_header_en,
                header,
                header_en,
                para,
                para_en,
                num_one,
                num_two,
                num_three,
                num_text_one,
                num_text_one_en,
                num_text_two,
                num_text_two_en,
                num_text_three,
                num_text_three_en,
                });
            
                if (response.status === 200) {
                // Update AllNumbers state after a successful update
                setAllNumbers((prevNumbers) => ({
                    ...prevNumbers,
                    header,
                    header_en,
                    sec_header,
                    sec_header_en,
                    para,
                    para_en,
                    num_one,
                    num_two,
                    num_three,
                    num_text_one,
                    num_text_one_en,
                    num_text_two,
                    num_text_two_en,
                    num_text_three,
                    num_text_three_en,
                }));
            
        
                Loading.classList.remove("active");
                let alertS = document.querySelector(".alert-success");
                alertS.classList.add("active");
        
                setTimeout(() => {
                alertS.classList.remove("active");
                }, 3000);
            } else {
                Loading.classList.remove("active");
                let alertD = document.querySelector(".alert-danger");
                alertD.classList.add("active");
                setTimeout(() => {
                alertD.classList.remove("active");
                }, 3000);
            }
            } catch (error) {
            console.error(error);
            alert("Server error");
            }
        };




    axios.defaults.withCredentials = true;
    
    const getNumbers = (itemId) => {
        let table = "numbers";
        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setAllNumbers(res.data);
                }
            })
            .catch((err) => console.log(err));
    };




    const getIfLogin = () => {
        axios.get('session')
            .then((res) => {
                if (!res.data.valid || id <= 0 || id == null || id == "") {
                    navigate('/admin-login');
                } else {
                    getNumbers(id);
                }
            })
            .catch((err) => console.log(err));
    };
    



    useEffect(() => {
        getIfLogin();
        getNumbers(id);
    }, []);
    
    return (
        <>

<div className='loading-screen'>
                            <div class="loader">
                                <div class="outer"></div>
                                <div class="middle"></div>
                                <div class="inner"></div>
                            </div>

                        </div>
            <section className='dashboard'>
                <div className='edit-form'>

                <div className='alert-success'>
                        <h1> تم تعديل البيانات  <i class="las la-check-circle"></i></h1>
                    </div>

                    <div className='alert-danger'>
                        <h1>حدث خطأ اثناء تعديل البيانات <i class="las la-exclamation-triangle"></i></h1>

                    </div>



                    <h1> تعديل الصفحة الرئيسية :  {formInputs.header}</h1>
                    <form onSubmit={handleSubmit}>
                
                            <div className='input'>
                                <label> العنوان  </label>
                                <input value={formInputs.header}  onChange={handleChange} type='text' required="required"  maxLength="250" name='header'/>
                            </div>

                            
                            <div className='input'>
                                <label> العنوان بالانجليزية  </label>
                                <input value={formInputs.header_en}  onChange={handleChange} type='text' required="required"  maxLength="250" name='header_en'/>
                            </div>


                            <div className='input'>
                                <label> العنوان الفرعي  </label>
                                <input  value={formInputs.sec_header}  onChange={handleChange} type='text' required="required"  maxLength="500" name='sec_header'/>
                            </div>

                            
                            <div className='input'>
                                <label> العنوان الفرعي بالانجليزية  </label>
                                <input  value={formInputs.sec_header_en}  onChange={handleChange} type='text' required="required"  maxLength="500" name='sec_header_en'/>
                            </div>


                            
                            <div className='input'>
                                <label> النص  </label>
                                <textarea defaultValue={formInputs.para} name='para' required="required" onChange={handleChange} maxLength="1000">
                                    
                                </textarea>
                            </div>

                                    
                            <div className='input'>
                                <label> النص بالانجليزية  </label>
                                <textarea defaultValue={formInputs.para_en} name='para_en' required="required" onChange={handleChange} maxLength="1000">
                                    
                                </textarea>
                            </div>


                            <div className='input'>
                                <label> الرقم الاول  </label>
                                <input  value={formInputs.num_one}  onChange={handleChange} type='text' required="required"  maxLength="100" name='num_one'/>
                            </div>


                                
                            <div className='input'>
                                <label> نص الرقم الاول  </label>
                                <input  value={formInputs.num_text_one}  onChange={handleChange} type='text' required="required"  maxLength="200" name='num_text_one'/>
                            </div>

                            <div className='input'>
                                <label>  نص الرقم الاول بالانجليزية </label>
                                <input  value={formInputs.num_text_one_en}  onChange={handleChange} type='text' required="required"  maxLength="200" name='num_text_one_en'/>
                            </div>


                            
                            <div className='input'>
                                <label> الرقم الثاني  </label>
                                <input  value={formInputs.num_two}  onChange={handleChange} type='text' required="required"  maxLength="100" name='num_two'/>
                            </div>


                                
                            <div className='input'>
                                <label> نص الرقم الثاني  </label>
                                <input  value={formInputs.num_text_two}  onChange={handleChange} type='text' required="required"  maxLength="200" name='num_text_two'/>
                            </div>

                            <div className='input'>
                                <label>  نص الرقم الثاني بالانجليزية </label>
                                <input  value={formInputs.num_text_two_en}  onChange={handleChange} type='text' required="required"  maxLength="200" name='num_text_two_en'/>
                            </div>

                            <div className='input'>
                                <label> الرقم الثالث  </label>
                                <input  value={formInputs.num_three}  onChange={handleChange} type='text' required="required"  maxLength="100" name='num_three'/>
                            </div>


                                
                            <div className='input'>
                                <label> نص الرقم الثالث  </label>
                                <input  value={formInputs.num_text_three}  onChange={handleChange} type='text' required="required"  maxLength="200" name='num_text_three'/>
                            </div>

                            <div className='input'>
                                <label>  نص الرقم الثالث بالانجليزية </label>
                                <input  value={formInputs.num_text_three_en}  onChange={handleChange} type='text' required="required"  maxLength="200" name='num_text_three_en'/>
                            </div>



                                <button disabled={!formChanged} type='submit'> تحديث </button>
                            </form>
                        </div>
                    </section>
                </>
            );
            }
            


            export default EditNumbers;
