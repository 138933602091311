import '../css/reviews.css';

import UserImg1 from '../images/2.webp';
import UserImg2 from '../images/3.webp';
import UserImg3 from '../images/2.webp';
import UserImg4 from '../images/3.webp';

import LongDeco2 from '../images/icons/decorlong.svg';

import arrowLeft from '../images/icons/arrowleft.svg';
import arrowRight from '../images/icons/arrowright.svg';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Autoplay, Navigation, Pagination } from 'swiper';

import React, { useState, useEffect } from "react";
import axios from '../../config/index';



function Reviews() {

    
    const [AllReviews, setAllReviews] = useState([]);

    const getAllReviews = () => {
        axios.post("AllItems", {
            table: "reviews",
        })
        .then((res) => {
            if (res.data) {
                setAllReviews(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };

    useEffect(() => {
        getAllReviews();
    }, []);



    const currentPath = window.location.pathname;
    const isARLanguage = currentPath.includes('jp');
    const importARStyles = async () => {
        if (isARLanguage) {
        try {
            // Import the AR-specific style sheet
            const arStyles = await import('../css/Jp.css');
            arStyles.default(); // Apply the styles if needed
        } catch (error) {
            console.error('Error importing AR styles:', error);
        }
        }
    };

    React.useEffect(() => {
        importARStyles();
    }, [currentPath]);



    return (
        <>
            <section className='reviews'>
                <div className='buttons'>
                    <button className='prev-btn'> <img src={arrowLeft} alt='left arrow' /> </button>
                    <button className='next-btn'> <img src={arrowRight} alt='right arrow' /> </button>
                </div>

                <div className='container'>
                    <div className='reviews-content'>
                        <div className='header'>
                            <h1> <span></span> <span></span> <span></span> 
                                レビュー
                                <span></span> <span></span> <span></span> 
                            </h1>

                            <p>  
                                Yamatakoを選んでくれてありがとう。忘れられない食事の体験を提供します。
                            </p>
                        </div>

                        <div className='all-reviews'>
                            <Swiper grabCursor={true} spaceBetween={50} slidesPerView={1} navigation={{
                                nextEl: '.next-btn',
                                prevEl: '.prev-btn',
                            }} 
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                modules={[Autoplay, Navigation]}
                            >

                        {
                        AllReviews && AllReviews.length > 0 && (

                        AllReviews.map((review, index) => (
                            
                            <SwiperSlide>
                                <div className='review'>
                                    <img className='decoration' src={LongDeco2} alt="decoration"/>
                                    <img className='user-img' src={`../uploads/${review.img}`} alt="reviewr Image"/>

                                    <div className='text'>
                                        <p> 
                                            {review.para_ch}
                                        </p>
                                        <h3>  {review.name_ch}</h3>
                                        <h4>  {review.position_ch} </h4>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )))}


                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Reviews;
