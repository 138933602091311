import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState, useEffect } from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';




function EditLandingSlide() {



    useEffect(() => {
        axios.get('session')
            .then((res) => {
                if (res.data.valid) {
                    if (res.data.type !== "admin") {
                        navigate('/admin-login');
                    }
                } else {
                    // Handle the case when session is not valid
                }
            })
            .catch((err) => console.log(err));
    }, []);




    const [formChanged, setFormChanged] = useState(false); // Track if the form has changed


    const handleInputChange = () => {
        setFormChanged(true); // Set the formChanged flag to true when any input changes
    };




    const navigate = useNavigate();
    const [LandingSlide, setLandingSlide] = useState({});
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');


    const [formInputs, setFormInputs] = useState({
        header: "",
        header_ar: "",
        header_ch: "",

        sec_header: "",
        sec_header_ar: "",
        sec_header_ch: "",

        para: "",
        para_ar: "",
        para_ch: "",


        img: null,
    });
    
    // Use formInputs to set the initial value of inputs
    useEffect(() => {
        if (LandingSlide.id) {
        setFormInputs({
            header: LandingSlide.header,
            header_ar: LandingSlide.header_ar,
            header_ch: LandingSlide.header_ch,

            sec_header: LandingSlide.sec_header,
            sec_header_ar: LandingSlide.sec_header_ar,
            sec_header_ch: LandingSlide.sec_header_ch,

            para: LandingSlide.para,
            para_ar: LandingSlide.para_ar,
            para_ch: LandingSlide.para_ch,

            img: null,
        });
        }
    }, [LandingSlide]);

        const handleChange = (e) => {
            handleInputChange();
            const { name, value, type } = e.target;
            const inputValue = type === "file" ? e.target.files[0] : value;
        
            setFormInputs((prevFormInputs) => ({
            ...prevFormInputs,
            [name]: inputValue,
            }));
        };


        
        const handleSubmit = async (e) => {
            e.preventDefault();
        
            let Loading = document.querySelector(".loading-screen");
            Loading.classList.add("active");
        
            const { header, header_ar,header_ch, sec_header, sec_header_ar,sec_header_ch,para,para_ar,para_ch, img } = formInputs;
        
            if (!header || !header_ar || !header_ch || !sec_header || !sec_header_ar || !sec_header_ch || !para || !para_ar || !para_ch ) {
            return alert("All fields are required");
            }
        
            try {
            const form = new FormData();
            form.append("id", id);
            form.append("header", header);
            form.append("header_ar", header_ar);
            form.append("header_ch", header_ch);

            form.append("sec_header", sec_header);
            form.append("sec_header_ar", sec_header_ar);
            form.append("sec_header_ch", sec_header_ch);

            
            form.append("para", para);
            form.append("para_ar", para_ar);
            form.append("para_ch", para_ch);


            form.append("img", img);
        
            const response = await axios.post("/update-landing-slide", form);
        
            if (response.status === 200) {
                // Update LandingSlide state after a successful update
                setLandingSlide((prevLandingSlide) => ({
                ...prevLandingSlide,
                header, header_ar,header_ch, sec_header, sec_header_ar,sec_header_ch,para,para_ar,para_ch
                }));
        
                Loading.classList.remove("active");
                let alertS = document.querySelector(".alert-success");
                alertS.classList.add("active");
        
                setTimeout(() => {
                alertS.classList.remove("active");
                }, 3000);
            } else {
                Loading.classList.remove("active");
                let alertD = document.querySelector(".alert-danger");
                alertD.classList.add("active");
                setTimeout(() => {
                alertD.classList.remove("active");
                }, 3000);
            }
            } catch (error) {
            console.error(error);
            alert("Server error");
            }
        };




    axios.defaults.withCredentials = true;
    
    const getLandingSlide = (itemId) => {
        let table = "landing_slides";
        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setLandingSlide(res.data);
                }
            })
            .catch((err) => console.log(err));
    };




    const getIfLogin = () => {
        axios.get('session')
            .then((res) => {
                if (!res.data.valid || id <= 0 || id == null || id == "") {
                    navigate('/admin-login');
                } else {
                    getLandingSlide(id);
                }
            })
            .catch((err) => console.log(err));
    };
    



    useEffect(() => {
        getIfLogin();
        getLandingSlide(id);
    }, []);
    
    return (
        <>

<div className='loading-screen'>
                            <div class="loader">
                                <div class="outer"></div>
                                <div class="middle"></div>
                                <div class="inner"></div>
                            </div>

                        </div>
            <section className='dashboard'>
                <div className='edit-form'>

                
                <div className='alert-success'>
                        <h1> Data Added successfully  <i class="las la-check-circle"></i></h1>
                    </div>

                    <div className='alert-danger'>
                        <h1> Failed To Add Data <i class="las la-exclamation-triangle"></i></h1>

                    </div>


                    <form onSubmit={handleSubmit}>
                

            
                        <div className='input'>
                            <label> Header  </label>
                            <input value={formInputs.header} onChange={handleChange} type='text' required="required"  maxLength="200" name='header'/>
                        </div>

                        
                        <div className='input'>
                            <label> header ( arabic )  </label>
                            <input  value={formInputs.header_ar} onChange={handleChange} type='text' required="required"  maxLength="200" name='header_ar'/>
                        </div>


                        <div className='input'>
                            <label> header ( japan ) </label>
                            <input  value={formInputs.header_ch} onChange={handleChange} type='text' required="required"  maxLength="200" name='header_ch'/>
                        </div>

                        <div className='input'>
                            <label> Sub Header  </label>
                            <input  value={formInputs.sec_header} onChange={handleChange} type='text' required="required"  maxLength="200" name='sec_header'/>
                        </div>

                        
                        <div className='input'>
                            <label> Sub header ( arabic )  </label>
                            <input  value={formInputs.sec_header_ar} onChange={handleChange} type='text' required="required"  maxLength="200" name='sec_header_ar'/>
                        </div>


                        <div className='input'>
                            <label> Sub header ( japan ) </label>
                            <input  value={formInputs.sec_header_ch} onChange={handleChange} type='text' required="required"  maxLength="200" name='sec_header_ch'/>
                        </div>




                        <div className='input'>
                            <label> Text  </label>
                            <textarea value={formInputs.para} onChange={handleChange} type='text' required="required"  maxLength="700" name='para'>
                                
                            </textarea>
                        </div>

                        
                        <div className='input'>
                            <label> Text ( arabic )  </label>
                            <textarea  value={formInputs.para_ar} onChange={handleChange} type='text' required="required"  maxLength="700" name='para_ar'>
                                
                            </textarea>                    
                        </div>


                        <div className='input'>
                            <label> Text ( japan ) </label>
                            <textarea value={formInputs.para_ch} onChange={handleChange} type='text' required="required"  maxLength="700" name='para_ch'>
                                
                            </textarea>
                        </div>





                        <div className='input'>
                            <label> Image  </label>
                            <input  onChange={handleChange} type='file' name="img" accept="image/*"/>
                        </div>




                                <button disabled={!formChanged} type='submit'> Update </button>
                            </form>
                        </div>
                    </section>
                </>
            );
            }
            


            export default EditLandingSlide;
