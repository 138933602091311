import './css/contact.css';

import ContactImg from './images/contact.webp';

function Contact(){
    return(
        <>
            <section className='contact'>
                <div className='container'>
                    <div className='contact-container'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <div className='contact-content'>
                                    <div className='overlay'></div>
                                    <img src={ContactImg} alt="contactImg"  />
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <div className='contact-content'>
                                        <h1> <span></span> <span></span> <span></span> 
                                            CONTACT FORM
                                            <span></span> <span></span> <span></span> 
                                        </h1>
                                        <p>
                                            Get in touch with us via email. Just fill the fields
                                            below to contact us.
                                        </p>

                                        <form>
                                            <input placeholder='Your name *' type='text' required />
                                            <input placeholder='Your email *' type='text' required />
                                            <input placeholder='Your comment *' type='text' required />

                                            <button type='submit'> Send Message </button>
                                        </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact;