import '../css/navbar.css';

import NavImg from '../images/icons/navshape.svg';
import NavLiImg from '../images/icons/li.svg';

import Logo from '../images/logo.png';
import { Link as RouterLink } from 'react-router-dom';

import { useEffect, useRef, useState } from "react";
import $ from 'jquery';
import React from 'react';

function Navbar() {
   
    const currentPath = window.location.pathname;
    const isARLanguage = currentPath.includes('jp');
    const importARStyles = async () => {
        if (isARLanguage) {
        try {
            // Import the AR-specific style sheet
            const arStyles = await import('../css/Jp.css');
            arStyles.default(); // Apply the styles if needed
        } catch (error) {
            console.error('Error importing AR styles:', error);
        }
        }
    };

    React.useEffect(() => {
        importARStyles();
    }, [currentPath]);



    useEffect(() => {
        $("#open-nav").on("click", function () {
            $(".nav-elements").slideDown(400);
            $(".nav-elements").css({ display: "flex" });
        });

        $("#close-nav").on("click", function () {
            $(".nav-elements").slideUp(400);
        });

        if ($(window).width() < 950) {
            $(".nav-elements ul li").on("click", function () {
                $(".nav-elements").slideUp(400);
            });
        }

        if ($(window).width() < 950) {
            $(".nav-elements ul li a").on("click", function () {
                $(".nav-elements").slideUp(400);
            });
        }

        const loadingElement = document.getElementById("loading");
        if (loadingElement) {
            loadingElement.remove();
        }
    }, []);

    return (
        <>
            <nav>
                <div className='dec-img'>
                    <img src={NavImg} alt="nav-img" />
                </div>

                <div className='container-ll'>
                    <div className='nav-content'>


                        <button id="open-nav"> <i className="las la-bars"></i> </button>
                        <div className="nav-elements">
                            <button id="close-nav"> <i className="las la-times"></i> </button>
                            <ul>
                                <li>
                                    <RouterLink to="/jp/">
                                        <img src={NavLiImg} alt="nav-img" />
                                        ホームページ
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/jp/our-menu">
                                        <img src={NavLiImg} alt="nav-img" />
                                        メニュー
                                    </RouterLink>
                                </li>

                                <li>
                                    <RouterLink to="/jp/about-us">
                                        <img src={NavLiImg} alt="nav-img" />
                                        私たちについて
                                    </RouterLink>
                                </li>

                                <li>
                                    <div className='logo'>
                                        <img src={Logo} alt="logo" />
                                        {/* <h1> Yamatake </h1> */}
                                    </div>
                                </li>

                                <li>
                                    <a target='_blank' href="https://goo.gl/maps/22LHhRGoQmGn1RdX8">
                                        <img src={NavLiImg} alt="nav-img" />
                                        弊社の場所
                                    </a>
                                </li>

                                <li>
                                    <RouterLink to="/jp/contact-us">
                                        <img src={NavLiImg} alt="nav-img" />
                                        お問い合わせ
                                    </RouterLink>
                                </li>
                                
                                <li>
                                    <i className="las la-globe"></i>
                                    <RouterLink to="/ar">
                                        AR
                                    </RouterLink>
                                    <RouterLink to="/">
                                        EN
                                    </RouterLink>
                                    <RouterLink to="/jp">
                                        日本語
                                    </RouterLink>
                                </li>



                            </ul>

                        </div>
                    </div>
                </div>

                <div className='dec-img'>
                    <img src={NavImg} alt="nav-img" />
                </div>

            </nav>
        </>
    )
}

export default Navbar;
