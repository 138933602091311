import './css/about.css';
import LandingShape from './images/icons/landingshape.svg';

import AboutImg1 from './images/2.webp';
import AboutImg2 from './images/3.webp';

import React, { useState, useEffect } from "react";
import axios from '../config/index';

function About(){

    const [AboutData, setAboutData] = useState([]);
    const itemId = 1;
    const getAboutData = (itemId) => {
        let table = "about_us";
        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setAboutData(res.data);
                }
            })
            .catch((err) => console.log(err));
    };


    useEffect(() => {
        getAboutData(itemId);
    }, []);


    return(
        <>
            <section className='about'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='about-header'>
                                <h1> <span></span> <span></span> <span></span> 
                                        ABOUT US
                                    <span></span> <span></span> <span></span> 
                                </h1>

                                <p>
                                    What sets us apart is our dedication to inclusivity
                                </p>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                            <div className='about-content'>
                            <img src={`uploads/${AboutData.img_1}`} alt="about shape" />
                                <div className='overlay'></div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                            <div className='about-content'>
                                <img className='shape' src={LandingShape} alt="about shape" />

                                <p> 
                                    {AboutData.para}
                                </p>

                                <a href="https://goo.gl/maps/22LHhRGoQmGn1RdX8"> Our Location </a>

                            </div>
                        </div>

                        
                        <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                            <div className='about-content'>
                                <img src={`uploads/${AboutData.img_2}`}  alt='about image' />
                                <div className='overlay'></div>
                            </div>
                        </div>



                    </div>
                </div>
            </section>
        </>
    )
}

export default About;