import './css/whychooseus.css';
import chooseusimg from './images/11.webp';

import liicon from './images/icons/li.svg';

function WhyChooseUs(){
    return(
        <>
            <section className='why-choose-us'>
                <div className='container'>
                    <div className='why-choose-us-container'>
                        <div className='row'>
                            <div className='col-lg-7 col-md-7 col-sm-12 col-12'>
                                <div className='why-choose-us-content'>
                                    <div className='overlay'></div>
                                    <img src={chooseusimg} alt="choose us img" />
                                </div>
                            </div>

                            <div className='col-lg-5 col-md-5 col-sm-12 col-12'>
                                <div className='why-choose-us-content'>
                                    <h1> <span></span> <span></span> <span></span> 
                                            WHY CHOOSE US
                                        <span></span> <span></span> <span></span> 
                                    </h1>

                                    <p> 
                                        We invite you to try our Takoyaki at least once and experience the rich flavors
                                    </p>

                                    <div className='reason'>
                                        <h2> Authentic Taste </h2>
                                        <h4> 
                                            <img src={liicon} alt='icon'/>
                                            Our Takoyaki mirrors the exact taste of the ones made in Japan, promising a truly authentic experience
                                        </h4>
                                    </div>

                                    
                                    <div className='reason'>
                                        <h2> Halal Assurance </h2>
                                        <h4> 
                                            <img src={liicon} alt='icon'/>
                                            We understand the importance of catering to diverse preferences. That's why our Takoyaki, including the sauce and soup stock, is halal
                                        </h4>
                                    </div>


                                    
                                    <div className='reason'>
                                        <h2>  Culinary Journey </h2>
                                        <h4> 
                                            <img src={liicon} alt='icon'/>
                                            Embark on a delicious journey through the streets of Japan without leaving Egypt. Our Takoyaki is a flavorful adventure waiting to be savored
                                        </h4>
                                    </div>

                                    <a href='https://goo.gl/maps/22LHhRGoQmGn1RdX8'> Try TakoYaki </a>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WhyChooseUs;