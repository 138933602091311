import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState, useEffect } from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';




function EditCurrency() {



    useEffect(() => {
        axios.get('session')
            .then((res) => {
                if (res.data.valid) {
                    if (res.data.type !== "admin") {
                        navigate('/admin-login');
                    }
                } else {
                    // Handle the case when session is not valid
                }
            })
            .catch((err) => console.log(err));
    }, []);





    const navigate = useNavigate();
    const [currency, setCurrency] = useState({});
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');


    const [name, setName] = useState("");



    const [formChanged, setFormChanged] = useState(false); // Track if the form has changed


    const handleInputChange = () => {
        setFormChanged(true); // Set the formChanged flag to true when any input changes
    };




    const handleSubmit = (event) => {
        event.preventDefault();

                
        let Loading = document.querySelector(".loading-screen"); 
        Loading.classList.add("active");


    
        const data = {
            name: name || "",
        };
        


        if (id) {
            data.id = id;
        }
    
        axios.put("/update-currency", data)
            .then((response) => {
                    
                Loading.classList.remove("active");

                let alertS = document.querySelector(".alert-success"); 
                alertS.classList.add("active");
                setTimeout(() => {
                    alertS.classList.remove("active");
                }, 3000);

            })
            .catch((error) => {
                console.log(error);
            });
    };
    





    axios.defaults.withCredentials = true;
    
    const getCurrency = (itemId) => {
        let table = "currency";
        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setCurrency(res.data);
                }
            })
            .catch((err) => console.log(err));
    };




    const getIfLogin = () => {
        axios.get('session')
            .then((res) => {
                if (!res.data.valid || id <= 0 || id == null || id == "") {
                    navigate('/admin-login');
                } else {
                    getCurrency(id);
                }
            })
            .catch((err) => console.log(err));
    };
    



    useEffect(() => {
        getIfLogin();
        getCurrency(id);
    }, []);
    
    return (
        <>

        
<div className='loading-screen'>
                            <div class="loader">
                                <div class="outer"></div>
                                <div class="middle"></div>
                                <div class="inner"></div>
                            </div>

                        </div>
            <section className='dashboard'>
                <div className='edit-form'>
                    <h1> تعديل العملة </h1>
                    <form onSubmit={handleSubmit}>

                        
                <div className='alert-success'>
            <h1> تم تعديل العملة  <i className="las la-check-circle"></i></h1>
        </div>
        <div className='alert-danger'>
            <h1>حدث خطأ اثناء تسجيل البيانات <i className="las la-exclamation-triangle"></i></h1>
        </div>




                        <div className='input'>
                            <label> الاسم  </label>
                            <input defaultValue={currency.name}  onChange={(event) => {setName(event.target.value);handleInputChange(event);}} type='text' required="required"  maxLength="100" name='name'/>
                        </div>



                                <button  disabled={!formChanged} type='submit'> حفظ </button>
                            </form>
                        </div>
                    </section>
                </>
            );
            }
            


            export default EditCurrency;
