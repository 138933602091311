import '../css/about.css';
import LandingShape from '../images/icons/landingshape.svg';

import AboutImg1 from '../images/2.webp';
import AboutImg2 from '../images/3.webp';

import React, { useState, useEffect } from "react";
import axios from '../../config/index';



function About(){

    
    

    
    const [AboutData, setAboutData] = useState([]);
    const itemId = 1;
    const getAboutData = (itemId) => {
        let table = "about_us";
        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setAboutData(res.data);
                }
            })
            .catch((err) => console.log(err));
    };


    useEffect(() => {
        getAboutData(itemId);
    }, []);


    const currentPath = window.location.pathname;
    const isARLanguage = currentPath.includes('jp');
    const importARStyles = async () => {
        if (isARLanguage) {
        try {
            // Import the AR-specific style sheet
            const arStyles = await import('../css/Jp.css');
            arStyles.default(); // Apply the styles if needed
        } catch (error) {
            console.error('Error importing AR styles:', error);
        }
        }
    };

    React.useEffect(() => {
        importARStyles();
    }, [currentPath]);


    return(
        <>
            <section className='about'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='about-header'>
                                <h1> <span></span> <span></span> <span></span> 
                                        私たちについて
                                    <span></span> <span></span> <span></span> 
                                </h1>

                                <p>
                                    私たちを特別な存在にするのは、包括性への献身です。
                                </p>
                            </div>
                        </div>

                        
                        <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                            <div className='about-content'>
                                <img src={`../uploads/${AboutData.img_1}`} alt='about image' />
                                <div className='overlay'></div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                            <div className='about-content'>
                                <img className='shape' src={LandingShape} alt="about shape" />

                                <p> 
                                    {AboutData.para_ch}
                                </p>

                                <a href="https://goo.gl/maps/22LHhRGoQmGn1RdX8"> 私たちの場所 </a>

                            </div>
                        </div>

                        
                        <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                            <div className='about-content'>
                                <img src={`../uploads/${AboutData.img_2}`}  alt='about image' />
                                <div className='overlay'></div>
                            </div>
                        </div>




                    </div>
                </div>
            </section>
        </>
    )
}

export default About;
