import '../css/whychooseus.css';
import chooseusimg from '../images/11.webp';

import liicon from '../images/icons/li.svg';
import React from 'react';

function WhyChooseUs() {

       
    const currentPath = window.location.pathname;
    const isARLanguage = currentPath.includes('jp');
    const importARStyles = async () => {
        if (isARLanguage) {
        try {
            // Import the AR-specific style sheet
            const arStyles = await import('../css/Jp.css');
            arStyles.default(); // Apply the styles if needed
        } catch (error) {
            console.error('Error importing AR styles:', error);
        }
        }
    };

    React.useEffect(() => {
        importARStyles();
    }, [currentPath]);




    return (
        <>
            <section className='why-choose-us'>
                <div className='container'>
                    <div className='why-choose-us-container'>
                        <div className='row'>
                            <div className='col-lg-7 col-md-7 col-sm-12 col-12'>
                                <div className='why-choose-us-content'>
                                    <div className='overlay'></div>
                                    <img src={chooseusimg} alt="choose us img" />
                                </div>
                            </div>

                            <div className='col-lg-5 col-md-5 col-sm-12 col-12'>
                                <div className='why-choose-us-content'>
                                    <h1> <span></span> <span></span> <span></span> 
                                            なぜ当店を選ぶのか
                                        <span></span> <span></span> <span></span> 
                                    </h1>

                                    <p> 
                                        私たちのたこ焼きを一度試して、豊かな風味を体験してみてください
                                    </p>

                                    <div className='reason'>
                                        <h2> 本物の味 </h2>
                                        <h4> 
                                            <img src={liicon} alt='icon'/>
                                            当店のたこ焼きは、日本で作られるものとまったく同じ味を再現しており、真に本物の体験をお約束します
                                        </h4>
                                    </div>

                                    
                                    <div className='reason'>
                                        <h2> ハラール保証 </h2>
                                        <h4> 
                                            <img src={liicon} alt='icon'/>
                                            私たちは多様な好みに対応する重要性を理解しています。そのため、当店のたこ焼き、ソース、出汁も含めてハラールです
                                        </h4>
                                    </div>


                                    
                                    <div className='reason'>
                                        <h2> 料理の冒険 </h2>
                                        <h4> 
                                            <img src={liicon} alt='icon'/>
                                            エジプトを離れることなく、日本の街を通り抜けるおいしい冒険に出発しましょう。当店のたこ焼きは、味わい深い冒険が待っています
                                        </h4>
                                    </div>

                                    <a href='https://goo.gl/maps/22LHhRGoQmGn1RdX8'> たこ焼きを試してみる </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WhyChooseUs;
