import './css/navbar.css';

import NavImg from './images/icons/navshape.svg';
import NavLiImg from './images/icons/li.svg';

import Logo from './images/logo.png';
import { Link as RouterLink } from 'react-router-dom';

import { useEffect,useRef,useState  } from "react";
import $ from 'jquery';

import axios from '../config/index';


function Navbar(){


    
    const [PhoneNumber, setPhonenumber] = useState([]);

    const getPhonnumber = () => {
        let table = "social";
        let itemId = 8;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setPhonenumber(res.data);
                }
            })
            .catch((err) => console.log(err));
    };


    useEffect(() => {
        getPhonnumber();
    }, []);

    
    useEffect(() => {
        $("#open-nav").on("click", function() {
            $(".nav-elements").slideDown(400);
            $(".nav-elements").css({display: "flex"});
        });

        $("#close-nav").on("click", function() {
            $(".nav-elements").slideUp(400);
        });

        if ($(window).width() < 950) {
            $(".nav-elements ul li").on("click", function() {
                $(".nav-elements").slideUp(400);
            });
        }

        if ($(window).width() < 950) {
            $(".nav-elements ul li a").on("click", function() {
                $(".nav-elements").slideUp(400);
            });
        }


    const loadingElement = document.getElementById("loading");
    if (loadingElement) {
        loadingElement.remove();
    }
    }, []);
    


    return(
        <>
            <nav>
                <div className='dec-img'>
                    <img src={NavImg} alt="nav-img" />
                </div>

                    <div className='container-ll'>
                        <div className='nav-content'>
                                
                                
                        <button id="open-nav"> <i className="las la-bars"></i> </button>
                        <div className="nav-elements">
                        <button id="close-nav"> <i className="las la-times"></i> </button>
                                    <ul>
                                        <li>
                                            <RouterLink to="/">
                                                <img src={NavLiImg} alt="nav-img" />
                                                Home Page
                                            </RouterLink>
                                        </li>

                                        <li>
                                            <RouterLink to="/our-menu">
                                                <img src={NavLiImg} alt="nav-img" />

                                                Menu
                                            </RouterLink>
                                        </li>

                                        <li>
                                            <RouterLink to="/about-us">
                                                <img src={NavLiImg} alt="nav-img" />

                                                About Us
                                            </RouterLink>
                                        </li>

                                        <li>
                                            <div className='logo'> 
                                                <img src={Logo} alt="logo" />
                                                {/* <h1> Yamatake </h1> */}
                                            </div>
                                        </li>

                                        <li>
                                            <a target='_blank' href="https://goo.gl/maps/22LHhRGoQmGn1RdX8">
                                                <img src={NavLiImg} alt="nav-img" />

                                                Our Location
                                            </a>
                                        </li>

                                        <li>
                                            <RouterLink to="/contact-us">
                                                <img src={NavLiImg} alt="nav-img" />

                                                Contact Us
                                            </RouterLink>
                                        </li>
                                        <li>
                                            <i className="las la-globe"></i>
                                            <RouterLink to="/ar">
                                                AR
                                            </RouterLink>
                                            <RouterLink to="/">
                                                EN
                                            </RouterLink>
                                            <RouterLink to="/jp">
                                                日本語
                                            </RouterLink>
                                        </li>



                                    </ul>

                                </div>
                        </div>
                    </div>

                <div className='dec-img'>
                    <img src={NavImg} alt="nav-img" />
                </div>

            </nav>
        </>
    )
}

export default Navbar;