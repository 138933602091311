import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState, useEffect } from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';

import { isEmptyObject } from 'jquery';



function EditCategory() {


    const navigate = useNavigate();
    const [order, setOrder] = useState({});
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const [AllProducts, setAllProducts] = useState([]);
    const [AllProductsFiltered, setAllProductsFiltered] = useState([]);


    useEffect(() => {
        const checkSession = async () => {
            try {
                const res = await axios.get('session');
                if (res.data.valid) {
                    if (res.data.type !== "admin") {
                        navigate('/admin-login');
                    }
                } else {
                    // Handle the case when the session is not valid
                }
            } catch (err) {
                console.error('Error checking session:', err);
            }
        };

        checkSession();
    }, []);

    const getOrder = async (itemId) => {
        try {
            let table = "orders";
            const res = await axios.post('get-item', { itemId, table });
            if (!res.data.message) {
                setOrder(res.data);
                console.log(res.data)
            }
        } catch (err) {
            console.error('Error getting order:', err);
        }
    };

    const getIfLogin = async () => {
        try {
            const res = await axios.get('session');
            if (!res.data.valid || id <= 0 || id == null || id === "") {
                navigate('/admin-login');
            } else {
                await getOrder(id);
            }
        } catch (err) {
            console.error('Error checking login:', err);
        }
    };

    useEffect(() => {
        const getAllProducts = async () => {
            try {
                const res = await axios.post('AllItems', { table: 'products' });
                if (res.data) {
                    setAllProducts(res.data);

                    const filteredProducts = res.data.filter(product => order.productids.split(",").map(Number).includes(product.id));
                    setAllProductsFiltered(filteredProducts);

                } else {
                    console.error("Error happened");
                }
            } catch (err) {
                console.error('Error getting all products:', err);
            }
        };

        if (!isEmptyObject(order)) {
            getAllProducts();
        }
    }, [order]);

    useEffect(() => {
        getIfLogin();
        getOrder(id);
        console.log(order)
    }, []);
    

            return (
                <>
                <section className='dashboard'>
                    <div className='edit-form'>
                    {order !== undefined && order !== null && order !== "" && !isEmptyObject(order) ? (
                        (() => {
                            const OrderProductsQuantity = order.productnumbers.split(',');

                            const renderedProducts = AllProductsFiltered.map((product, index) => (
                            <div className='product' key={index}>
                                <img src={`uploads/${product.img}`} alt="ordered product image" />
                                <h1>{product.name}</h1>
                                <h2>{product.price} <span> JOD </span> </h2>
                                <h3><span>{OrderProductsQuantity[index]}</span> : الكمية</h3>
                                <h3><span>{product.id}</span> : رقم المنتج</h3>
                            </div>
                            ));

                            return (
                            <div className='order' key={order.id}>
                                <div className='ordered-products'>
                                {renderedProducts}
                                </div>

                                <div className='user-info'>
                                <h2>{order.name} <span>: الاسم</span></h2>
                                <h2>{order.phonenumber} <span>: رقم الهاتف</span></h2>
                                <h2>{order.email} <span>: البريد الإلكتروني</span></h2>
                                <h2>{order.totalprice} <span>: إجمالي الفاتورة</span></h2>
                                <h2>{order.order_num} <span>: رقم الطلب</span></h2>
                                <h2>{order.trans_num} <span>: رقم العملية</span></h2>
                                <h2>
                                    {order.status === 'settled' ? 'عملية ناجحة' : 'عملية فاشلة'}
                                    <span>: الحالة</span>
                                </h2>
                                <p>{order.address}</p>
                                </div>
                            </div>
                            );
                        })()
                        ) : (
                        <tr>
                            <td colSpan={5}>تحميل...</td>
                        </tr>
                        )}

                    </div>
                </section>
                </>
            );
            
            }
            


            export default EditCategory;
