import './css/dashboardtable.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import Landing from '../Landing';




function AllServices(){


        
    useEffect(() => {
        axios
    .get('session-admin')
    .then((res) => {
        if (res.data.valid) {

            if(res.data.type == "admin"){

            }else{
                navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);



    
    const navigate = useNavigate();
    const [AllOrders, setAllOrders] = useState([]);

    axios.defaults.withCredentials = true;





    const getAllOrders = ()=>{
        axios.post('AllItems', {
            table: 'orders',
        })
        .then((res) => {
            if (res.data) {
                console.log(res.data)
                setAllOrders(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    




    const getIfLogin= ()=>{
        
    axios
    .get('session')
    .then((res) => {
        if (!res.data.valid) {
            console.log(res.data.valid);
            navigate('/admin-login');

        }else{
            console.log(res.data.valid);

        }
    })
    .catch((err) => console.log(err));

    }





    const deleteItem = (id) => {
        axios
        .delete(`delete-item/${id}`, {
            data: {
            table: 'orders',
            },
        })
        .then((response) => {
            if (response.data.message === 'Item deleted successfully') {
                getAllOrders();
            } else {
            console.error('Failed to delete result:', response.data.message);
            }
        })
        .catch((error) => {
            console.error('Error deleting result:', error);
        });
    };




useEffect(() => {

    getAllOrders();
    getIfLogin();

}, []);




    

    return(
        <>
            <section className='dashboard'>



                <div className='results-table'>
                <table>

                    <thead>
                        <tr>
                            <th> رقم الطلب </th>
                            <th> اسم العميل </th>
                            <th> إجمالي الفاتورة </th>
                            <th> الحالة </th>
                            <th> الخيارات </th>

                        </tr>
                    </thead>
                    <tbody>

                    {AllOrders && AllOrders.length > 0 ? (
                        AllOrders.reverse().map((order) => (
                            <tr key={order.id}>
                            <td><h6>{order.id}</h6></td>
                            <td><h2>{order.name}</h2></td>
                            <td><h2>{order.totalprice}</h2></td>
                            <td>
                            <h2>
                                {(() => {
                                    if (order.status === 'settled') {
                                    return 'عملية ناجحة';
                                    } else {
                                    return 'عملية فاشلة';
                                    }
                                })()}
                                </h2>
                                </td>

                                <td>
                                <Link className='edit' to={`/view-orders?id=${order.id}`}> <i class="las la-eye"></i> </Link>
                            </td>

                            
                            <td>
                                <button className='delete' onClick={() => deleteItem(order.id)}> <i class="las la-trash-alt"></i> </button>
                            </td>

                            </tr>
                        ))
                        ) : (
                        <tr>
                            <td colSpan="4"> فارغ </td>
                        </tr>
                        )}

                        

                    </tbody>
                    </table>
                </div>
            </section>
        
        </>
    )
}

export default AllServices;