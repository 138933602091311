import '../css/contact.css';

import ContactImg from '../images/contact.webp';
import React from 'react';

function Contact(){

   
    const currentPath = window.location.pathname;
    const isARLanguage = currentPath.includes('jp');
    const importARStyles = async () => {
        if (isARLanguage) {
        try {
            // Import the AR-specific style sheet
            const arStyles = await import('../css/Jp.css');
            arStyles.default(); // Apply the styles if needed
        } catch (error) {
            console.error('Error importing AR styles:', error);
        }
        }
    };

    React.useEffect(() => {
        importARStyles();
    }, [currentPath]);


    return(
        <>
            <section className='contact'>
                <div className='container'>
                    <div className='contact-container'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <div className='contact-content'>
                                    <div className='overlay'></div>
                                    <img src={ContactImg} alt="contactImg"  />
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <div className='contact-content'>
                                        <h1> <span></span> <span></span> <span></span> 
                                            お問い合わせフォーム
                                            <span></span> <span></span> <span></span> 
                                        </h1>
                                        <p>
                                            メールでお問い合わせください。以下のフィールドに必要事項を記入してください。
                                        </p>

                                        <form>
                                            <input placeholder='お名前 *' type='text' required />
                                            <input placeholder='メールアドレス *' type='text' required />
                                            <input placeholder='コメント *' type='text' required />

                                            <button type='submit'> メッセージを送信 </button>
                                        </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact;
