import './css/dashboardtable.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';




function AllReviews(){


        
    useEffect(() => {
        axios
    .get('session-admin')
    .then((res) => {
        if (res.data.valid) {

            if(res.data.type == "admin"){

            }else{
                navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);



    
    const navigate = useNavigate();
    const [allReviews, setAllReviews] = useState([]);

    axios.defaults.withCredentials = true;



    

    const GetAllReviews = ()=>{
        axios.post('AllItems', {
            table: 'reviews',
        })
        .then((res) => {
            if (res.data) {
                setAllReviews(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    





    const getIfLogin= ()=>{
        
    axios
    .get('session')
    .then((res) => {
        if (!res.data.valid) {
            navigate('/admin-login');

        }else{
            console.log(res.data.valid);

        }
    })
    .catch((err) => console.log(err));

    }





    const deleteItem = (id) => {
        axios
        .delete(`delete-item/${id}`, {
            data: {
            table: 'reviews',
            },
        })
        .then((response) => {
            if (response.data.message === 'Item deleted successfully') {
                GetAllReviews();
            } else {
            console.error('Failed to delete result:', response.data.message);
            }
        })
        .catch((error) => {
            console.error('Error deleting result:', error);
        });
    };




useEffect(() => {

    GetAllReviews();
    getIfLogin();

}, []);




    

    return(
        <>
            <section className='dashboard'>



                <div className='results-table'>
                <Link className='new-item' to='/add-review'> Add Review  </Link>
                <table>

                    <thead>
                        <tr>
                            <th> # </th>
                            <th> IMG </th>
                            <th> Customer Name </th>
                        </tr>
                    </thead>
                    <tbody>
                    {allReviews.length ? (
                    allReviews.map((review) => {
                        return (
                        <tr key={review.id}>
                            
                            <td>
                            <h6>{review.id}</h6>
                            </td>

                            <td><img src={`./uploads/${review.img}`} alt="Landing Slide Image"/></td>

                            <td>
                            <h6>{review.name}</h6>
                            </td>
                            <td><Link className='edit' to={`/edit-review?id=${review.id}`}> <i class="las la-edit"></i> </Link></td>


                            <td>
                            <button className='delete' onClick={() => deleteItem(review.id)}>
                                <i className="las la-trash-alt"></i>
                            </button>
                            </td>
                        </tr>
                        );
                    })
                    ) : (
                    <tr>
                        <td colSpan={5}>Loading...</td>
                    </tr>
                    )}
                    </tbody>
                </table>

                </div>
            </section>
        
        </>
    )
}

export default AllReviews;