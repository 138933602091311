import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState, useEffect } from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';




function EditProduct() {



    useEffect(() => {
        axios.get('session')
            .then((res) => {
                if (res.data.valid) {
                    if (res.data.type !== "admin") {
                        navigate('/admin-login');
                    }
                } else {
                    // Handle the case when session is not valid
                }
            })
            .catch((err) => console.log(err));
    }, []);




    const [formChanged, setFormChanged] = useState(false); // Track if the form has changed


    const handleInputChange = () => {
        setFormChanged(true); // Set the formChanged flag to true when any input changes
    };




    const navigate = useNavigate();
    const [ProductData, setProductData] = useState({});
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');


    const [formInputs, setFormInputs] = useState({
        para: "",
        para_ar: "",
        para_ch: "",

        name: "",
        name_ar: "",
        name_ch: "",

        price: "",
        place: "",

        img: null,
    });
    
    // Use formInputs to set the initial value of inputs
    useEffect(() => {
        if (ProductData.id) {
        setFormInputs({
            
            para: ProductData.para,
            para_ar: ProductData.para_ar,
            para_ch: ProductData.para_ch,

            name: ProductData.name,
            name_ar: ProductData.name_ar,
            name_ch: ProductData.name_ch,

            price: ProductData.price,
            place: ProductData.place,

            img: null,
        });
        }
    }, [ProductData]);

        const handleChange = (e) => {
            handleInputChange();
            const { name, value, type } = e.target;
            const inputValue = type === "file" ? e.target.files[0] : value;
        
            setFormInputs((prevFormInputs) => ({
            ...prevFormInputs,
            [name]: inputValue,
            }));
        };


        
        const handleSubmit = async (e) => {
            e.preventDefault();
        
            let Loading = document.querySelector(".loading-screen");
            Loading.classList.add("active");
        
            const { para, para_ar,para_ch, name, name_ar,name_ch,price, place ,img } = formInputs;
        
            if (!para || !para_ar || !para_ch || !name || !name_ar || !name_ch || !price || !place ) {
            return alert("All fields are required");
            }
        
            try {
            const form = new FormData();
            form.append("id", id);
            form.append("para", para);
            form.append("para_ar", para_ar);
            form.append("para_ch", para_ch);

            form.append("name", name);
            form.append("name_ar", name_ar);
            form.append("name_ch", name_ch);

            
            form.append("price", price);
            form.append("place", place);

            form.append("img", img);
        
            const response = await axios.post("/update-product", form);
        
            if (response.status === 200) {
                // Update LandingSlide state after a successful update
                setProductData((prevProductData) => ({
                ...prevProductData,
                para, para_ar,para_ch, name, name_ar,name_ch,price
                }));
        
                Loading.classList.remove("active");
                let alertS = document.querySelector(".alert-success");
                alertS.classList.add("active");
        
                setTimeout(() => {
                alertS.classList.remove("active");
                }, 3000);
            } else {
                Loading.classList.remove("active");
                let alertD = document.querySelector(".alert-danger");
                alertD.classList.add("active");
                setTimeout(() => {
                alertD.classList.remove("active");
                }, 3000);
            }
            } catch (error) {
            console.error(error);
            alert("Server error");
            }
        };




    axios.defaults.withCredentials = true;
    
    const getProductData = (itemId) => {
        let table = "products";
        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setProductData(res.data);
                }
            })
            .catch((err) => console.log(err));
    };




    const getIfLogin = () => {
        axios.get('session')
            .then((res) => {
                if (!res.data.valid || id <= 0 || id == null || id == "") {
                    navigate('/admin-login');
                } else {
                    getProductData(id);
                }
            })
            .catch((err) => console.log(err));
    };
    



    useEffect(() => {
        getIfLogin();
        getProductData(id);
    }, []);
    
    return (
        <>

<div className='loading-screen'>
                            <div class="loader">
                                <div class="outer"></div>
                                <div class="middle"></div>
                                <div class="inner"></div>
                            </div>

                        </div>
            <section className='dashboard'>
                <div className='edit-form'>

                
                <div className='alert-success'>
                        <h1> Data Added successfully  <i class="las la-check-circle"></i></h1>
                    </div>

                    <div className='alert-danger'>
                        <h1> Failed To Add Data <i class="las la-exclamation-triangle"></i></h1>

                    </div>


                    <form onSubmit={handleSubmit}>
                

            
                        
                    <div className='input'>
                        <label> Product Name  </label>
                        <input   value={formInputs.name} onChange={handleChange} type='text' required="required"  maxLength="200" name='name'/>
                    </div>

                    
                    <div className='input'>
                        <label> Product Name ( arabic )  </label>
                        <input   value={formInputs.name_ar} onChange={handleChange} type='text' required="required"  maxLength="200" name='name_ar'/>
                    </div>


                    <div className='input'>
                        <label> Product Name ( japan ) </label>
                        <input   value={formInputs.name_ch} onChange={handleChange} type='text' required="required"  maxLength="200" name='name_ch'/>
                    </div>
                    

                        <div className='input'>
                        <label> Product Description  </label>
                        <textarea   value={formInputs.para} onChange={handleChange} type='text' required="required"  maxLength="200" name='para'>
                            
                        </textarea>
                    </div>

                    
                    <div className='input'>
                        <label> Product Description ( arabic )  </label>
                        <textarea   value={formInputs.para_ar} onChange={handleChange} type='text' required="required"  maxLength="200" name='para_ar'>
                            
                        </textarea>                    
                    </div>


                    <div className='input'>
                        <label> Product Description ( japan ) </label>
                        <textarea   value={formInputs.para_ch} onChange={handleChange} type='text' required="required"  maxLength="200" name='para_ch'>
                            
                        </textarea>
                    </div>



                    <div className='input'>
                        <label> Price  </label>
                        <input   value={formInputs.price} onChange={handleChange} type='text' required="required"  maxLength="200" name='price'/>
                    </div>

                            
                    <div className='input'>
                        <label> Product place  </label>
                        <select  value={formInputs.place} onChange={handleChange}  required="required" name='place'>
                            <option value="">Choose place</option>
                            <option value="Best Seller">Best Seller</option>
                            <option value="Our Choice">Our Choice</option>
                            <option value="Must Try">Must Try</option>

                        </select>
                    </div>



                        <div className='input'>
                            <label> Image  </label>
                            <input  onChange={handleChange} type='file' name="img" accept="image/*"/>
                        </div>




                                <button disabled={!formChanged} type='submit'> Update </button>
                            </form>
                        </div>
                    </section>
                </>
            );
            }
            


            export default EditProduct;
