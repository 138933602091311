import '../css/contact.css';

import ContactImg from '../images/contact.webp';

import React from 'react';

function Contact() {

    const currentPath = window.location.pathname;
    const isARLanguage = currentPath.includes('ar');
    const importARStyles = async () => {
        if (isARLanguage) {
        try {
            // Import the AR-specific style sheet
            const arStyles = await import('../css/Ar.css');
            arStyles.default(); // Apply the styles if needed
        } catch (error) {
            console.error('Error importing AR styles:', error);
        }
        }
    };

    React.useEffect(() => {
        importARStyles();
    }, [currentPath]);

    return (
        <>
            <section className='contact'>
                <div className='container'>
                    <div className='contact-container'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <div className='contact-content'>
                                    <div className='overlay'></div>
                                    <img src={ContactImg} alt="صورة اتصال" />
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <div className='contact-content'>
                                    <h1><span></span> <span></span> <span></span>
                                        نموذج الاتصال
                                        <span></span> <span></span> <span></span>
                                    </h1>
                                    <p>
                                        تواصل معنا عبر البريد الإلكتروني. ما عليك سوى ملء الحقول
                                        أدناه للتواصل معنا.
                                    </p>

                                    <form>
                                        <input placeholder='اسمك *' type='text' required />
                                        <input placeholder='بريدك الإلكتروني *' type='text' required />
                                        <input placeholder='تعليقك *' type='text' required />

                                        <button type='submit'>إرسال الرسالة</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact;
