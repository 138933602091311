import '../css/whychooseus.css';
import chooseusimg from '../images/11.webp';

import liicon from '../images/icons/li.svg';
import React from 'react';

function WhyChooseUs() {

    const currentPath = window.location.pathname;
    const isARLanguage = currentPath.includes('ar');
    const importARStyles = async () => {
        if (isARLanguage) {
        try {
            // Import the AR-specific style sheet
            const arStyles = await import('../css/Ar.css');
            arStyles.default(); // Apply the styles if needed
        } catch (error) {
            console.error('Error importing AR styles:', error);
        }
        }
    };

    React.useEffect(() => {
        importARStyles();
    }, [currentPath]);


    
    return (
        <>
            <section className='why-choose-us'>
                <div className='container'>
                    <div className='why-choose-us-container'>
                        <div className='row'>
                            <div className='col-lg-7 col-md-7 col-sm-12 col-12'>
                                <div className='why-choose-us-content'>
                                    <div className='overlay'></div>
                                    <img src={chooseusimg} alt="choose us img" />
                                </div>
                            </div>

                            <div className='col-lg-5 col-md-5 col-sm-12 col-12'>
                                <div className='why-choose-us-content'>
                                    <h1> <span></span> <span></span> <span></span>
                                        لماذا نختارنا
                                        <span></span> <span></span> <span></span>
                                    </h1>

                                    <p>
                                        ندعوك لتجربة تاكوياكي لدينا مرة واحدة على الأقل وتجربة النكهات الغنية
                                    </p>

                                    <div className='reason'>
                                        <h2>طعم أصيل</h2>
                                        <h4>
                                            <img src={liicon} alt='أيقونة' />
                                            تاكوياكي لدينا تعكس الطعم الدقيق لتلك التي يتم إعدادها في اليابان، مع وعد تجربة حقيقية أصيلة
                                        </h4>
                                    </div>


                                    <div className='reason'>
                                        <h2>ضمان حلال</h2>
                                        <h4>
                                            <img src={liicon} alt='أيقونة' />
                                            نحن نفهم أهمية تلبية تفضيلات متنوعة. لهذا السبب، تاكوياكي لدينا، بما في ذلك الصلصة وحساء المرق، حلال
                                        </h4>
                                    </div>


                                    <div className='reason'>
                                        <h2>رحلة في عالم الطهي</h2>
                                        <h4>
                                            <img src={liicon} alt='أيقونة' />
                                            انطلق في رحلة لذيذة عبر شوارع اليابان دون مغادرة مصر. تاكوياكي لدينا هو مغامرة ذات نكهة في انتظار أن تُذوق
                                        </h4>
                                    </div>

                                    <a href='https://goo.gl/maps/22LHhRGoQmGn1RdX8'>جرب تاكوياكي</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WhyChooseUs;
