import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';
import ShowButton from '../ShowButton';






function AddReview(){


        
    useEffect(() => {
        axios
    .get('session')
    .then((res) => {
        if (res.data.valid) {

            if(res.data.type == "admin"){
            }else{
                navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);





    const navigate = useNavigate();


    
    const [para, setPara] = useState("");
    const [para_ar, setParaar] = useState("");
    const [para_ch, setparach] = useState("");
    
    const [name, setName] = useState("");
    const [name_ar, setNamear] = useState("");
    const [name_ch, setNamech] = useState("");

    const [position, setPosition] = useState("");
    const [position_ar, setPositionar] = useState("");
    const [position_ch, setPositionch] = useState("");





    const [img, setImg] = useState("");


    const handleSubmit = (event) => {
        event.preventDefault();

        let Loading = document.querySelector(".loading-screen"); 
        Loading.classList.add("active");

        const data = new FormData();

                
        data.append("para", para || "");
        data.append("para_ar", para_ar || "");
        data.append("para_ch", para_ch || "");



        data.append("name", name || "");
        data.append("name_ar", name_ar || "");
        data.append("name_ch", name_ch || "");

        data.append("position", position || "");
        data.append("position_ar", position_ar || "");
        data.append("position_ch", position_ch || "");



        if (img) {
            data.append("img", img);
        }
    
    
        const config = {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        };
    
        axios
        .post("/add-review", data, config)
        .then((response) => {
            
            Loading.classList.remove("active");

            let alertS = document.querySelector(".alert-success"); 
            alertS.classList.add("active");


            setTimeout(() => {
                alertS.classList.remove("active");
            }, 3000);


        })
        .catch((error) => {
            
            Loading.classList.remove("active");

            let alertD = document.querySelector(".alert-danger"); 
            alertD.classList.add("active");
            setTimeout(() => {
                alertD.classList.remove("active");
            }, 3000);
            console.log(error);
        });
    };
    


    axios.defaults.withCredentials = true;



    const getIfLogin= ()=>{
        
    axios
    .get('session')
    .then((res) => {
        if (!res.data.valid) {
            navigate('/admin-login');

        }
        
    })
    .catch((err) => console.log(err));

    }




    useEffect(() => {
        getIfLogin();
    }, []);
    




    

    return(
        <>

                    <div className='loading-screen'>
                            <div class="loader">
                                <div class="outer"></div>
                                <div class="middle"></div>
                                <div class="inner"></div>
                            </div>

                        </div>
            <section className='dashboard'>



                <div className='edit-form'>

                    <div className='alert-success'>
                        <h1> Data Added successfully  <i class="las la-check-circle"></i></h1>
                    </div>

                    <div className='alert-danger'>
                        <h1> Failed To Add Data <i class="las la-exclamation-triangle"></i></h1>

                    </div>

                    <h1> Add Review</h1>

                    <form onSubmit={handleSubmit}>





                    <div className='input'>
                        <label> Review Text  </label>
                        <textarea  onChange={(event) => setPara(event.target.value)} type='text' required="required"  maxLength="700" name='para'>
                            
                        </textarea>
                    </div>

                    
                    <div className='input'>
                        <label> Review Text ( arabic )  </label>
                        <textarea  onChange={(event) => setParaar(event.target.value)} type='text' required="required"  maxLength="700" name='para_ar'>
                            
                        </textarea>                    
                    </div>


                    <div className='input'>
                        <label> Review Text ( japan ) </label>
                        <textarea  onChange={(event) => setparach(event.target.value)} type='text' required="required"  maxLength="700" name='para_ch'>
                            
                        </textarea>
                    </div>


                        
                    <div className='input'>
                        <label> Customer Name  </label>
                        <input  onChange={(event) => setName(event.target.value)} type='text' required="required"  maxLength="200" name='name'/>
                    </div>

                    
                    <div className='input'>
                        <label> Customer Name ( arabic )  </label>
                        <input  onChange={(event) => setNamear(event.target.value)} type='text' required="required"  maxLength="200" name='name_ar'/>
                    </div>


                    <div className='input'>
                        <label> Customer Name ( japan ) </label>
                        <input  onChange={(event) => setNamech(event.target.value)} type='text' required="required"  maxLength="200" name='name_ch'/>
                    </div>

                    <div className='input'>
                        <label> Customer Position  </label>
                        <input  onChange={(event) => setPosition(event.target.value)} type='text' required="required"  maxLength="200" name='position'/>
                    </div>

                    
                    <div className='input'>
                        <label>  Customer Position ( arabic )  </label>
                        <input  onChange={(event) => setPositionar(event.target.value)} type='text' required="required"  maxLength="200" name='position_ar'/>
                    </div>


                    <div className='input'>
                        <label>  Customer Position ( japan ) </label>
                        <input  onChange={(event) => setPositionch(event.target.value)} type='text' required="required"  maxLength="200" name='position_ch'/>
                    </div>







                    <div className='input'>
                        <label> Image  </label>
                        <input onChange={(event) => setImg(event.target.files[0])} type='file' name="img" accept="image/*"/>
                    </div>

                
                    <button type='submit'> Save </button>

                    </form>
                </div>
            </section>
        
        </>
    )
}

export default AddReview;