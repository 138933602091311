import './css/dashboard.css';
import './css/dashboardtable.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';




function Dashboard(){


    const navigate = useNavigate();
    axios.defaults.withCredentials = true;

    const [reviewsCount, setReviewsCount] = useState(0);
    const [landingslidesCount, setLandingSlidesCount] = useState(0);
    const [productsCount, setProductsCount] = useState(0);

    const [products, setProducts] = useState(0);

    

    const getAllProducts = ()=>{
        axios.post('AllItems', {
            table: 'products',
        })
        .then((res) => {
            if (res.data) {
                console.log(res.data)
                setProducts(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    

    
    useEffect(() => {
        axios
    .get('session')
    .then((res) => {
        if (res.data.valid) {

            if(res.data.type == "admin"){
                navigate('/dashboard');
            }else{
                // navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);




    const getLandingSlidesCount = () => {
        axios.post('get-item-count', {
            table: 'landing_slides',
        })
        .then((res) => {
            if (res.data) {
                setLandingSlidesCount(res.data.totalCount);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };



    
    const getReviewsCount = () => {
        axios.post('get-item-count', {
            table: 'reviews',
        })
        .then((res) => {
            if (res.data) {
                setReviewsCount(res.data.totalCount);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };



    
    const getProductsCount = () => {
        axios.post('get-item-count', {
            table: 'products',
        })
        .then((res) => {
            if (res.data) {
                setProductsCount(res.data.totalCount);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };


    const getIfLogin= ()=>{
        
    axios
    .get('session')
    .then((res) => {
        if (!res.data.valid) {
            console.log(res.data.valid);
            navigate('/admin-login');

        }else{
            console.log(res.data.valid);

        }
    })
    .catch((err) => console.log(err));

    }

    
useEffect(() => {
    getLandingSlidesCount();
    getReviewsCount();
    getProductsCount();
    getAllProducts();
    getIfLogin();

}, []);




    

    return(
        <>
            <section className='dashboard'>
                <div className='stats-content'>
                    <Link to="/all-orders">
                        <div className='stat'>
                            <i className="las la-users"></i>
                            <div className='info'>
                                <h1>{landingslidesCount}</h1>
                                <h2> Home Slides </h2>
                            </div>
                        </div>
                    </Link>
                    

                    <Link to="/all-services">
                        <div className='stat'>
                        <i className="las la-question"></i>
                            <div className='info'>
                                <h1>{reviewsCount}</h1>
                                <h2> Reviews  </h2>
                            </div>
                        </div>
                    </Link>


                    <Link to="/all-gifts">
                        <div className='stat'>
                        <i className="las la-poll-h"></i>
                            <div className='info'>
                                <h1>{productsCount}</h1>
                                <h2> Products </h2>
                            </div>
                        </div>
                    </Link>

                    
                </div>




                <div className='results-table'>

                <table>

                    <thead>
                        <tr>
                            <th> # </th>
                            <th> IMG </th>
                            <th> Name </th>
                            <th> Price </th>

                        </tr>
                    </thead>
                    <tbody>

                    {products && products.length > 0 ? (
                        products.map((product) => (
                            <tr key={product.id}>
                            <td><h6>{product.id}</h6></td>
                            <td><img src={`/uploads/${product.img}`} alt="Product Img"/></td>
                            <td><h2>{product.name}</h2></td>
                            <td><h2>{product.price}</h2></td>

                            <td><Link to={`/edit-product?id=${product.id}`}> Edit </Link></td>
                            </tr>
                        ))
                        ) : (
                        <tr>
                            <td colSpan="4"> There is no products to show ... </td>
                        </tr>
                        )}

                    </tbody>
                </table>

                </div>
            </section>
        
        </>
    )
}

export default Dashboard;