import '../css/ad.css';

import AdImg from '../images/12.webp';
import React from 'react';



import { useEffect,useState  } from "react";
import axios from '../../config/index';


function Ad() {

    const [PhoneNumber, setPhonenumber] = useState([]);


    const getPhonenumber = () => {
        let table = "social";
        let itemId = 8;
    
        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setPhonenumber(res.data);
                }
            })
            .catch((err) => console.log(err));
    };
    
    
    useEffect(() => {
        getPhonenumber();
    }, []);
    
    

    
    const currentPath = window.location.pathname;
    const isARLanguage = currentPath.includes('ar');
    const importARStyles = async () => {
        if (isARLanguage) {
        try {
            // Import the AR-specific style sheet
            const arStyles = await import('../css/Ar.css');
            arStyles.default(); // Apply the styles if needed
        } catch (error) {
            console.error('Error importing AR styles:', error);
        }
        }
    };

    React.useEffect(() => {
        importARStyles();
    }, [currentPath]);

    
    return (
        <>
            <section className='ad'>
                <div className='container'>
                    <div className='ad-content-container'>
                        <div className='row'>
                            <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                <div className='ad-content'>
                                    <h2>طقم تاكوياكي للبيع</h2>
                                    <h1><span></span> <span></span> <span></span>
                                        يميزنا
                                        <span></span> <span></span> <span></span>
                                    </h1>

                                    <p>
                                        ما يميزنا هو التفاني في التضمين. نحن نفهم ونحترم التفضيلات المتنوعة لمجتمعنا. لذلك، ليس فقط تاكوياكي لدينا لذيذة، ولكنها أيضًا تلتزم بأعلى معايير الحلال. من صلصة اللذيذة إلى مرقة الحساء الغنية، يتم تقديم كل عنصر بعناية لضمان تجربة ممتعة تلبي احتياجات الجميع.
                                    </p>

                                    <a href={`tel:${PhoneNumber.link}`}>
                                        اتصل بنا
                                    </a>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                <div className='ad-content'>
                                    <div className='overlay'></div>
                                    <img src={AdImg} alt="صورة إعلان" />
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                <div className='ad-content'>
                                    <h2>أفضل مجموعة</h2>
                                    <h1><span></span> <span></span> <span></span>
                                        دعوة
                                        <span></span> <span></span> <span></span>
                                    </h1>

                                    <p>
                                        ندعو شعب مصر إلى الانطلاق في رحلة ذوقية وتذوق الطعم الأصيل لتاكوياكي. سواء كنت من عشاق المأكولات اليابانية أو جديدًا على عروضها اللذيذة، نأمل في مشاركة طعم اليابان مع كل واحد منكم. شكرًا لاختيارك ياماتاكو كوجهة رئيسية لتجربة لا تُنسى لطعام الشارع الياباني.
                                    </p>

                                    <a href={`tel:${PhoneNumber.link}`}>
                                        اتصل بنا
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Ad;
