import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState, useEffect } from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';




function EditGallery() {



    useEffect(() => {
        axios.get('session')
            .then((res) => {
                if (res.data.valid) {
                    if (res.data.type !== "admin") {
                        navigate('/admin-login');
                    }
                } else {
                    // Handle the case when session is not valid
                }
            })
            .catch((err) => console.log(err));
    }, []);





    const navigate = useNavigate();
    const [gallery, setGallery] = useState({});
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');



    const handleImageChange = (event) => {
        const selectedImage = event.target.files[0];

        uploadImage(selectedImage);
    };



    const uploadImage = (file) => {
        const formData = new FormData();

        
                
        let Loading = document.querySelector(".loading-screen"); 
        Loading.classList.add("active");



        formData.append("image", file);
        formData.append("galleryId", id);

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };

        axios.post("upload-image-gallery", formData, config)
            .then((response) => {
                
                Loading.classList.remove("active");

                let alertS = document.querySelector(".alert-success"); 
                alertS.classList.add("active");
                setTimeout(() => {
                    alertS.classList.remove("active");
                }, 3000);



                return response.data;

            })
            .catch((error) => {

                
                Loading.classList.remove("active");

                let alertS = document.querySelector(".alert-success"); 
                alertS.classList.add("active");
                setTimeout(() => {
                    alertS.classList.remove("active");
                }, 3000);


                console.error("Error uploading image: ", error);
                return null;
            });
    };



    axios.defaults.withCredentials = true;
    const getGallery = (itemId) => {
        let table = "logo";
        axios.post('get-item', { itemId, table })
        .then((res) => {
            console.log(res.data); // Add this line to check the response data
            if (res.data.message) {
            // Handle the case when the team data is not found
            } else {
            setGallery(res.data);
            }
        })
        .catch((err) => console.log(err));
    };



    const getIfLogin = () => {
        axios.get('session')
            .then((res) => {
                if (!res.data.valid || id <= 0 || id == null || id == "") {
                    navigate('/admin-login');
                } else {
                    getGallery(id);
                }
            })
            .catch((err) => console.log(err));
    };
    




    useEffect(() => {
        getIfLogin();
        getGallery(id);

    }, []);

    
    return (
        <>
                    <div className='loading-screen'>
                            <div class="loader">
                                <div class="outer"></div>
                                <div class="middle"></div>
                                <div class="inner"></div>
                            </div>

                        </div>


        {Object.keys(gallery).length > 0 && (

            <section className='dashboard'>
                <div className='edit-form'>

                    
                <div className='alert-success'>
            <h1> تم تعديل البيانات  <i className="las la-check-circle"></i></h1>
        </div>
        <div className='alert-danger'>
            <h1>حدث خطأ اثناء تعديل البيانات <i className="las la-exclamation-triangle"></i></h1>
        </div>


                    <h1> تعديل اللوجو </h1>
                    <form >


                        <div className='input'>
                            <label> الصورة  </label>
                            <input onChange={handleImageChange} type='file' name="image" accept="image/*"/>
                        </div>


                            </form>
                        </div>
                    </section>
                    )}
                </>
            );
            }
            


            export default EditGallery;
