import './App.css';
import { Routes, Route, useLocation } from "react-router-dom";
import React, { useState, useRef } from "react";



import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Navbar from './components/Navbar';
import Landing from './components/Landing';
import About from './components/About';
import HomeMenu from './components/HomeMenu';
import VisitUs from './components/VisitUs';
import Reviews from './components/Reviews';
import Ad from './components/Ad';
import WhyChooseUs from './components/WhyChooseUs';
import Map from './components/Map';
import Footer from './components/Footer';
import Menu from './components/Menu';
import PageHeader from './components/PageHeader';
import Contact from './components/Contact';


//Ar Lang

import NavbarAr from './components/ar/Navbar';
import LandingAr from './components/ar/Landing';
import AboutAr from './components/ar/About';
import HomeMenuAr from './components/ar/HomeMenu';
import VisitUsAr from './components/ar/VisitUs';
import ReviewsAr from './components/ar/Reviews';
import AdAr from './components/ar/Ad';
import WhyChooseUsAr from './components/ar/WhyChooseUs';
import MapAr from './components/ar/Map';
import FooterAr from './components/ar/Footer';
import MenuAr from './components/ar/Menu';
import PageHeaderAr from './components/ar/PageHeader';
import ContactAr from './components/ar/Contact';





//Jp Lang

import NavbarJp from './components/jp/Navbar';
import LandingJp from './components/jp/Landing';
import AboutJp from './components/jp/About';
import HomeMenuJp from './components/jp/HomeMenu';
import VisitUsJp from './components/jp/VisitUs';
import ReviewsJp from './components/jp/Reviews';
import AdJp from './components/jp/Ad';
import WhyChooseUsJp from './components/jp/WhyChooseUs';
import MapJp from './components/jp/Map';
import FooterJp from './components/jp/Footer';
import MenuJp from './components/jp/Menu';
import PageHeaderJp from './components/jp/PageHeader';
import ContactJp from './components/jp/Contact';



// Admin Pages


import SideNav from "./components/Admin/SideNav";
import LoginAdmin from "./components/Admin/LoginAdmin";


import SearchBar from "./components/Admin/SearchBar";
import Dashboard from "./components/Admin/Dashboard";
import AllUsersPage from "./components/Admin/AllUsersPage";
import EditUser from "./components/Admin/EditUser";
import AddUser from "./components/Admin/AddUser";


import AllLandingSlides from './components/Admin/AllLandingSlides';
import EditLandingSlide from './components/Admin/EditLandingSlide';
import AddLandingSlide from './components/Admin/AddLandingSlide';

import AllServices from './components/Admin/AllServices';
import AddServices from './components/Admin/AddServices';
import EditServices from './components/Admin/EditServices';

import AllNumbers from './components/Admin/AllNumbers';
import EditNumbers from './components/Admin/EditNumbers';

import AllBenefits from './components/Admin/AllBenefits';
import EditBenefits from './components/Admin/EditBenefits';
import AllVideos from './components/Admin/AllVideos';
import AddVideos from './components/Admin/AddVideos';
import EditVideos from './components/Admin/EditVideos';
import AllTimeTables from './components/Admin/AllTimeTables';
import EditTimeTables from './components/Admin/EditTimeTables';

import AddCategory from './components/Admin/AddCategory';
import AllCategories from './components/Admin/AllCategories';
import EditCategory from './components/Admin/EditCategory';

import AddGallery from './components/Admin/AddGallery';
import AllGalleryPage from './components/Admin/AllGalleryPage';
import EditGallery from './components/Admin/EditGallery';


import EditAbout from './components/Admin/EditAbout';
import AllAbout from './components/Admin/AllAbout';


import EditSocial from './components/Admin/EditSocial';
import AllSocialPage from './components/Admin/AllSocialPage';
import AllGifts from './components/Admin/AllGifts';
import AllCurrency from './components/Admin/AllCurrency';
import EditCurrency from './components/Admin/EditCurrency';


import AllOrders from './components/Admin/AllOrders';
import ViewOrders from './components/Admin/ViewOrders';

import AddProduct from './components/Admin/AddProduct';
import AllProducts from './components/Admin/AllProducts';
import EditProduct from './components/Admin/EditProduct';

import AddReview from './components/Admin/AddReview';
import AllReviews from './components/Admin/AllReviews';
import EditReview from './components/Admin/EditReview';





function App() {


    
    const [sessionExists, setSessionExists] = useState("");
    const servicesRef = useRef(0);
    const location = useLocation();
    const hideHeaderFooter =
    location.pathname === "/dashboard" ||
    location.pathname === "/all-users" ||
    location.pathname === "/all-waiting-users" ||
    location.pathname === "/edit-user" ||
    location.pathname === "/add-user"||
    location.pathname === "/all-landing-slides"||
    location.pathname === "/add-landing-slide"||
    location.pathname === "/edit-landing-slide"||
    location.pathname === "/all-categories"||
    location.pathname === "/add-category"||
    location.pathname === "/edit-category"||
    location.pathname === "/all-numbers"||
    location.pathname === "/edit-number"||
    location.pathname === "/all-benefits"||
    location.pathname === "/edit-benefit"||
    location.pathname === "/all-videos"||
    location.pathname === "/add-video"||
    location.pathname === "/edit-video"||
    location.pathname === "/all-time-tables"||
    location.pathname === "/edit-time-table"||
    location.pathname === "/all-sub-services"||
    location.pathname === "/add-sub-service"||
    location.pathname === "/edit-sub-service"||
    location.pathname === "/all-gallery"||
    location.pathname === "/add-gallery"||
    location.pathname === "/edit-gallery" ||   
    location.pathname === "/all-about"||
    location.pathname === "/edit-about"||
    location.pathname === "/all-social"||
    location.pathname === "/all-orders"||
    location.pathname === "/all-gifts"||
    location.pathname === "/edit-currency"||
    location.pathname === "/all-currency"||
    location.pathname === "/all-orders"||
    location.pathname === "/view-orders"||
    location.pathname === "/view-orders"||
    location.pathname === "/all-products"||
    location.pathname === "/edit-product"||
    location.pathname === "/add-product"||
    location.pathname === "/all-reviews"||
    location.pathname === "/edit-review"||
    location.pathname === "/add-review"||
    location.pathname === "/edit-social";




return (
    <div className="App">

<SideNav
        className={hideHeaderFooter ? "" : "hide"}
        sessionExists={sessionExists}
        setSessionExists={setSessionExists}
        />
        <SearchBar 
            className={hideHeaderFooter ? "" : "hide"}
        />


            <Routes>
            

            <Route path="/" element={
                    <>
                        <Navbar className={!hideHeaderFooter ? "" : "hide"}/>
                        <Landing />
                        <About /> 
                        <HomeMenu />
                        <VisitUs />
                        <Reviews />
                        <Ad />
                        <WhyChooseUs />
                        <Map />
                        <Footer className={!hideHeaderFooter ? "" : "hide"}/>

                    </>
                } />

                <Route path="/our-menu" element={
                    <>
                        <Navbar className={!hideHeaderFooter ? "" : "hide"}/>

                        <Menu />
                        <Footer className={!hideHeaderFooter ? "" : "hide"}/>

                    </>
                } />

                
                <Route path="/about-us" element={
                    <>
                        <Navbar className={!hideHeaderFooter ? "" : "hide"}/>

                        <PageHeader header="ABOUT US" para="We invite the people of Egypt to embark on a culinary journey and savor the authentic taste of Takoyaki " />
                        <About /> 
                        <VisitUs />
                        <WhyChooseUs />
                        <Ad />
                        <Footer className={!hideHeaderFooter ? "" : "hide"}/>

                    </>
                } />

                
                <Route path="/contact-us" element={
                    <>
                                <Navbar className={!hideHeaderFooter ? "" : "hide"}/>

                        <PageHeader header="CONTACT US" para="Have a question, suggestion, or just want to say hello? We'd love to hear from you!" />
                        <VisitUs />
                        <Contact />
                        <Map />
                        <Footer className={!hideHeaderFooter ? "" : "hide"}/>

                    </>
                } />





                
            <Route path="/ar/" element={
                    <>
                        <NavbarAr className={!hideHeaderFooter ? "" : "hide"}/>
                        <LandingAr />
                        <AboutAr /> 
                        <HomeMenuAr />
                        <VisitUsAr />
                        <ReviewsAr />
                        <AdAr />
                        <WhyChooseUsAr />
                        <MapAr />
                        <FooterAr className={!hideHeaderFooter ? "" : "hide"}/>

                    </>
                } />

                <Route path="/ar/our-menu" element={
                    <>
                        <NavbarAr className={!hideHeaderFooter ? "" : "hide"}/>
                        <MenuAr />
                        <FooterAr className={!hideHeaderFooter ? "" : "hide"}/>

                    </>
                } />

                
                <Route path="/ar/about-us" element={
                    <>
                        <NavbarAr className={!hideHeaderFooter ? "" : "hide"}/>
                        <PageHeaderAr header="من نحن" para="ندعو شعب مصر للانطلاق في رحلة طهو والتذوق لتجربة الطعم الأصيل للطاكوياكي" />
                        <AboutAr /> 
                        <VisitUsAr />
                        <WhyChooseUsAr />
                        <AdAr />
                        <FooterAr className={!hideHeaderFooter ? "" : "hide"}/>

                    </>
                } />

                
                <Route path="/ar/contact-us" element={
                    <>
                        <NavbarAr className={!hideHeaderFooter ? "" : "hide"}/>
                        <PageHeaderAr header="اتصل بنا" para="هل لديك سؤال، اقتراح، أو ترغب فقط في قول مرحبًا؟ نحن نحب أن نسمع منك!" />
                        <VisitUsAr />
                        <ContactAr />
                        <MapAr />
                        <FooterAr className={!hideHeaderFooter ? "" : "hide"}/>

                    </>
                } />




                





                

                
            <Route path="/jp/" element={
                    <>
                        <NavbarJp className={!hideHeaderFooter ? "" : "hide"}/>
                        <LandingJp />
                        <AboutJp /> 
                        <HomeMenuJp />
                        <VisitUsJp />
                        <ReviewsJp />
                        <AdJp />
                        <WhyChooseUsJp />
                        <MapJp />
                        <FooterJp className={!hideHeaderFooter ? "" : "hide"}/>

                    </>
                } />

                <Route path="/jp/our-menu" element={
                    <>
                        <NavbarJp className={!hideHeaderFooter ? "" : "hide"}/>
                        <MenuJp />
                        <FooterJp className={!hideHeaderFooter ? "" : "hide"}/>

                    </>
                } />

                
                <Route path="/jp/about-us" element={
                    <>
                        <NavbarJp className={!hideHeaderFooter ? "" : "hide"}/>
                        <PageHeaderJp header="私たちについて" para="エジプトの人々に、料理の旅に乗り出して、たこ焼きの本物の味を味わっていただきたいとお伝えします" />
                        <AboutJp/> 
                        <VisitUsJp />
                        <WhyChooseUsJp />
                        <AdJp />
                        <FooterJp className={!hideHeaderFooter ? "" : "hide"}/>

                    </>
                } />

                
                <Route path="/jp/contact-us" element={
                    <>
                        <NavbarJp className={!hideHeaderFooter ? "" : "hide"}/>
                        <PageHeaderJp header="お問い合わせ" para="質問や提案がある場合、またはただ挨拶したい場合は、お知らせください！お便りをお待ちしています。" />
                        <VisitUsJp />
                        <ContactJp />
                        <MapJp />
                        <FooterJp className={!hideHeaderFooter ? "" : "hide"}/>

                    </>
                } />








                
            <Route path="/admin-login"element={<><LoginAdmin sessionExists={sessionExists} setSessionExists={setSessionExists} /></>}/>
            <Route path="/dashboard"element={ <><Dashboard /> </>}/>
            <Route path="/all-users" element={ <><AllUsersPage /> </>}/>
            <Route path="/edit-user" element={<><EditUser /></>} />
            <Route path="/add-user" element={<><AddUser /></>}/>
            <Route path="/all-users"element={<><AllUsersPage /></> }/>

            <Route path="/all-landing-slides"element={<><AllLandingSlides /></> }/>
            <Route path="/add-landing-slide"element={<><AddLandingSlide /></> }/>
            <Route path="/edit-landing-slide"element={<><EditLandingSlide /></> }/>

            <Route path="/all-categories"element={<><AllCategories /></> }/>
            <Route path="/add-category"element={<><AddCategory /></> }/>
            <Route path="/edit-category"element={<><EditCategory /></> }/>


            <Route path="/edit-number"element={<><EditNumbers /></> }/>
            <Route path="/all-numbers"element={<><AllNumbers /></> }/>
            <Route path="/edit-benefit"element={<><EditBenefits /></> }/>
            <Route path="/all-benefits"element={<><AllBenefits /></> }/>

            <Route path="/all-videos"element={<><AllVideos /></> }/>
            <Route path="/add-video"element={<><AddVideos /></> }/>
            <Route path="/edit-video"element={<><EditVideos /></> }/>

            <Route path="/all-time-tables"element={<><AllTimeTables /></> }/>
            <Route path="/edit-time-table"element={<><EditTimeTables /></> }/>

            
            <Route path="/add-gallery"element={<><AddGallery /></> }/>
            <Route path="/all-gallery"element={<><AllGalleryPage /></> }/>
            <Route path="/edit-gallery"element={<><EditGallery /></> }/>

            <Route path="/all-about" element={<><AllAbout /></> }/>
            <Route path="/edit-about" element={<><EditAbout /></> }/>


            <Route path="/all-gifts" element={<><AllGifts /></> }/>

            <Route path="/all-social" element={<><AllSocialPage /></> }/>
            <Route path="/edit-social" element={<><EditSocial /></> }/>

            <Route path="/all-orders" element={<><AllOrders /></> }/>
            <Route path="/view-orders" element={<><ViewOrders /></> }/>





            <Route path="/all-currency" element={<><AllCurrency /></> }/>
            <Route path="/edit-currency" element={<><EditCurrency /></> }/>


            <Route path="/all-orders" element={<><AllOrders /></> }/>
            <Route path="/view-orders" element={<><ViewOrders /></> }/>


            
            <Route path="/add-product"element={<><AddProduct /></> }/>
            <Route path="/all-products"element={<><AllProducts /></> }/>
            <Route path="/edit-product"element={<><EditProduct /></> }/>


            <Route path="/add-review"element={<><AddReview /></> }/>
            <Route path="/all-reviews"element={<><AllReviews /></> }/>
            <Route path="/edit-review"element={<><EditReview /></> }/>




        </Routes>

    </div>
);
}

export default App;
