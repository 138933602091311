import React, { useState, useEffect } from "react";
import axios from '../config/index';
import './css/landing.css';
import LandingShape from './images/icons/landingshape.svg';
import arrowLeft from './images/icons/arrowleft.svg';
import arrowRight from './images/icons/arrowright.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Navigation, Pagination } from 'swiper';

function Landing() {
    const [allLandingSlides, setAllLandingSlides] = useState([]);

    const getAllLandingSlides = () => {
        axios.post("AllItems", {
            table: "landing_slides",
        })
        .then((res) => {
            if (res.data) {
                setAllLandingSlides(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };

    useEffect(() => {
        getAllLandingSlides();
    }, []);

    return (
        <>
            <section className='landing'>
                <div className='overlay'></div>
                <div className='container'>
                    <Swiper
                        grabCursor={true}
                        spaceBetween={50}
                        slidesPerView={1}
                        navigation={{
                            nextEl: '.next-btn',
                            prevEl: '.prev-btn',
                        }}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay, Navigation]}
                    >
                        {
                    allLandingSlides && allLandingSlides.length > 0 && (

                        allLandingSlides.map((slide, index) => (
                            <SwiperSlide key={index}>
                                <div className="overlay-img">
                                    <img src={`uploads/${slide.img}`} alt="landing slide" />
                                    <div className="overlay"></div>
                                </div>
                                <div className='landing-content'>
                                    <img src={LandingShape} alt="landing shape img"/>
                                    <h2>{slide.sec_header}</h2>
                                    <h1>{slide.header}</h1>
                                    <p>{slide.para}</p>
                                </div>
                            </SwiperSlide>

                        )))}
                    </Swiper>
                </div>
                <div className='buttons'>
                    <button className='prev-btn'><img src={arrowLeft} alt='arrow left' /></button>
                    <button className='next-btn'><img src={arrowRight} alt='arrow right' /></button>
                </div>
            </section>
        </>
    );
}

export default Landing;
