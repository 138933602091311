import './css/dashboardtable.css';
import './css/editform.css';
import React, { useState, useEffect } from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';




function EditReview() {



    useEffect(() => {
        axios.get('session')
            .then((res) => {
                if (res.data.valid) {
                    if (res.data.type !== "admin") {
                        navigate('/admin-login');
                    }
                } else {
                    // Handle the case when session is not valid
                }
            })
            .catch((err) => console.log(err));
    }, []);




    const [formChanged, setFormChanged] = useState(false); // Track if the form has changed


    const handleInputChange = () => {
        setFormChanged(true); // Set the formChanged flag to true when any input changes
    };




    const navigate = useNavigate();
    const [ReviewData, setReviewData] = useState({});
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');


    const [formInputs, setFormInputs] = useState({
        para: "",
        para_ar: "",
        para_ch: "",

        name: "",
        name_ar: "",
        name_ch: "",

        position: "",
        position_ar: "",
        position_ch: "",



        img: null,
    });
    
    // Use formInputs to set the initial value of inputs
    useEffect(() => {
        if (ReviewData.id) {
        setFormInputs({
            
            para: ReviewData.para,
            para_ar: ReviewData.para_ar,
            para_ch: ReviewData.para_ch,

            name: ReviewData.name,
            name_ar: ReviewData.name_ar,
            name_ch: ReviewData.name_ch,

            position: ReviewData.position,
            position_ar: ReviewData.position_ar,
            position_ch: ReviewData.position_ch,


            img: null,
        });
        }
    }, [ReviewData]);

        const handleChange = (e) => {
            handleInputChange();
            const { name, value, type } = e.target;
            const inputValue = type === "file" ? e.target.files[0] : value;
        
            setFormInputs((prevFormInputs) => ({
            ...prevFormInputs,
            [name]: inputValue,
            }));
        };


        
        const handleSubmit = async (e) => {
            e.preventDefault();
        
            let Loading = document.querySelector(".loading-screen");
            Loading.classList.add("active");
        
            const { para, para_ar,para_ch, name, name_ar,name_ch,position,position_ar,position_ch, img } = formInputs;
        
            if (!para || !para_ar || !para_ch || !name || !name_ar || !name_ch || !position || !position_ar || !position_ch ) {
            return alert("All fields are required");
            }
        
            try {
            const form = new FormData();
            form.append("id", id);
            form.append("para", para);
            form.append("para_ar", para_ar);
            form.append("para_ch", para_ch);

            form.append("name", name);
            form.append("name_ar", name_ar);
            form.append("name_ch", name_ch);

            
            form.append("position", position);
            form.append("position_ar", position_ar);
            form.append("position_ch", position_ch);


            form.append("img", img);
        
            const response = await axios.post("/update-review", form);
        
            if (response.status === 200) {
                // Update LandingSlide state after a successful update
                setReviewData((prevLandingSlide) => ({
                ...prevLandingSlide,
                para, para_ar,para_ch, name, name_ar,name_ch,position,position_ar,position_ch,
                }));
        
                Loading.classList.remove("active");
                let alertS = document.querySelector(".alert-success");
                alertS.classList.add("active");
        
                setTimeout(() => {
                alertS.classList.remove("active");
                }, 3000);
            } else {
                Loading.classList.remove("active");
                let alertD = document.querySelector(".alert-danger");
                alertD.classList.add("active");
                setTimeout(() => {
                alertD.classList.remove("active");
                }, 3000);
            }
            } catch (error) {
            console.error(error);
            alert("Server error");
            }
        };




    axios.defaults.withCredentials = true;
    
    const getReviewdata = (itemId) => {
        let table = "reviews";
        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setReviewData(res.data);
                }
            })
            .catch((err) => console.log(err));
    };




    const getIfLogin = () => {
        axios.get('session')
            .then((res) => {
                if (!res.data.valid || id <= 0 || id == null || id == "") {
                    navigate('/admin-login');
                } else {
                    getReviewdata(id);
                }
            })
            .catch((err) => console.log(err));
    };
    



    useEffect(() => {
        getIfLogin();
        getReviewdata(id);
    }, []);
    
    return (
        <>

<div className='loading-screen'>
                            <div class="loader">
                                <div class="outer"></div>
                                <div class="middle"></div>
                                <div class="inner"></div>
                            </div>

                        </div>
            <section className='dashboard'>
                <div className='edit-form'>

                
                <div className='alert-success'>
                        <h1> Data Added successfully  <i class="las la-check-circle"></i></h1>
                    </div>

                    <div className='alert-danger'>
                        <h1> Failed To Add Data <i class="las la-exclamation-triangle"></i></h1>

                    </div>


                    <form onSubmit={handleSubmit}>
                

            
                    

                        <div className='input'>
                        <label> Review Text  </label>
                        <textarea   value={formInputs.para} onChange={handleChange} type='text' required="required"  maxLength="700" name='para'>
                            
                        </textarea>
                    </div>

                    
                    <div className='input'>
                        <label> Review Text ( arabic )  </label>
                        <textarea   value={formInputs.para_ar} onChange={handleChange} type='text' required="required"  maxLength="700" name='para_ar'>
                            
                        </textarea>                    
                    </div>


                    <div className='input'>
                        <label> Review Text ( japan ) </label>
                        <textarea   value={formInputs.para_ch} onChange={handleChange} type='text' required="required"  maxLength="700" name='para_ch'>
                            
                        </textarea>
                    </div>


                        
                    <div className='input'>
                        <label> Customer Name  </label>
                        <input   value={formInputs.name} onChange={handleChange} type='text' required="required"  maxLength="200" name='name'/>
                    </div>

                    
                    <div className='input'>
                        <label> Customer Name ( arabic )  </label>
                        <input   value={formInputs.name_ar} onChange={handleChange} type='text' required="required"  maxLength="200" name='name_ar'/>
                    </div>


                    <div className='input'>
                        <label> Customer Name ( japan ) </label>
                        <input   value={formInputs.name_ch} onChange={handleChange} type='text' required="required"  maxLength="200" name='name_ch'/>
                    </div>

                    <div className='input'>
                        <label> Customer Position  </label>
                        <input   value={formInputs.position} onChange={handleChange} type='text' required="required"  maxLength="200" name='position'/>
                    </div>

                    
                    <div className='input'>
                        <label>  Customer Position ( arabic )  </label>
                        <input  value={formInputs.position_ar} onChange={handleChange} type='text' required="required"  maxLength="200" name='position_ar'/>
                    </div>


                    <div className='input'>
                        <label>  Customer Position ( japan ) </label>
                        <input   value={formInputs.position_ch} onChange={handleChange} type='text' required="required"  maxLength="200" name='position_ch'/>
                    </div>



                        <div className='input'>
                            <label> Image  </label>
                            <input  onChange={handleChange} type='file' name="img" accept="image/*"/>
                        </div>




                                <button disabled={!formChanged} type='submit'> Update </button>
                            </form>
                        </div>
                    </section>
                </>
            );
            }
            


            export default EditReview;
