import '../css/pageheader.css';

import headerdeco from '../images/icons/decor.svg';

import React from 'react';

function PageHeader(props){

    const currentPath = window.location.pathname;
    const isARLanguage = currentPath.includes('ar');
    const importARStyles = async () => {
        if (isARLanguage) {
        try {
            // Import the AR-specific style sheet
            const arStyles = await import('../css/Ar.css');
            arStyles.default(); // Apply the styles if needed
        } catch (error) {
            console.error('Error importing AR styles:', error);
        }
        }
    };

    React.useEffect(() => {
        importARStyles();
    }, [currentPath]);


    
    return(
        <>
            <section className='page-header'>
                <div className='overlay'></div>

                <div className='container'>
                    <div className='page-header-content'>
                        <div>
                            <img src={headerdeco}  />
                            <h1> {props.header} </h1>
                            <img src={headerdeco}  />
                        </div>

                        <p>
                            {props.para}
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PageHeader;