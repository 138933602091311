import './css/dashboardtable.css';
import React, { useState,useEffect} from "react";
import axios from '../../config/index';
import { Link, useNavigate } from 'react-router-dom';
import Landing from '../Landing';




function AllGifts(){


        
    useEffect(() => {
        axios
    .get('session-admin')
    .then((res) => {
        if (res.data.valid) {

            if(res.data.type == "admin"){

            }else{
                navigate('/admin-login');
            }

        } else {

        }
    })
    .catch((err) => console.log(err));
    },[]);



    
    const navigate = useNavigate();
    const [allgifts, setAllGifts] = useState([]);

    axios.defaults.withCredentials = true;





    const getAllGifts = ()=>{
        axios.post('AllItems', {
            table: 'gifts',
        })
        .then((res) => {
            if (res.data) {
                setAllGifts(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };
    




    const getIfLogin= ()=>{
        
    axios
    .get('session')
    .then((res) => {
        if (!res.data.valid) {
            navigate('/admin-login');

        }else{

        }
    })
    .catch((err) => console.log(err));

    }


        const [selectedImage, setSelectedImage] = useState(null);

        const openModal = (imageSrc) => {
        setSelectedImage(imageSrc);
        document.body.classList.add('modal-open'); // Hide the scrollbar when the modal is open
        };
    
        const closeModal = () => {
        setSelectedImage(null);
        document.body.classList.remove('modal-open'); // Restore the scrollbar when the modal is closed
        };




    const deleteItem = (id) => {
        axios
        .delete(`delete-item/${id}`, {
            data: {
            table: 'gifts',
            },
        })
        .then((response) => {
            if (response.data.message === 'Item deleted successfully') {
                getAllGifts();
            } else {
            console.error('Failed to delete result:', response.data.message);
            }
        })
        .catch((error) => {
            console.error('Error deleting result:', error);
        });
    };




useEffect(() => {

    getAllGifts();
    getIfLogin();

}, []);




    

    return(
        <>
            <section className='dashboard'>



                <div className='results-table'>
                <table>

                    <thead>
                        <tr>
                            <th>ID</th>
                            <th> وصل الدفع </th>

                            <th> الخدمات المطلوبة </th>
                            <th> الاسعار </th>
                            <th> من </th>
                            <th> الى </th>
                            <th> رقم الهاتف  </th>
                            <th> رسالة الكيك </th>
                            <th> رسالة بطاقة الهدايا </th>

                            <th> التعليقات </th>
                            <th>العنوان</th>

                            <th>الخيارات</th>

                        </tr>
                    </thead>
                    <tbody>
                    {allgifts.length ? (

                    allgifts.map(gift => {
                        const giftServiceNamesArr = gift.services_names.split(',');
                        const giftPricesArr = gift.prices.split(',');
                        
                        return (
                            <>
                            <tr key={gift.id}>
                            <td><h6>{gift.id}</h6></td>
                            <td> <img  src={`uploads/${gift.pay_res}`}
                                    alt='Payment Receipt'
                                    onClick={() => openModal(`uploads/${gift.pay_res}`)} /></td>




                        <td> 
                            <h6>{
                                giftServiceNamesArr.map(
                                    servicename =>{
                                        return(
                                            <>
                                            <span>{servicename}</span>
                                            </>
                                        )
                                    }
                                )
                                }</h6></td>




                            <td><h6>{
                                giftPricesArr.map(
                                    price =>{
                                        return(
                                            <>
                                            <span>{price}</span>

                                            </>
                                        )
                                    }
                                )
                                }</h6></td>


                                <td><h6>{gift.from}</h6></td>
                                <td><h6>{gift.to}</h6></td>
                                <td><h6>{gift.friend_phonenumber}</h6></td>
                                <td><h6>{gift.cake_message}</h6></td>
                                <td><h6>{gift.gift_card}</h6></td>
                                <td><h6>{gift.comments}</h6></td>
                                <td><h6>{gift.address}</h6></td>

                            
                            <td>
                                <button className='delete' onClick={() => deleteItem(gift.id)}> <i class="las la-trash-alt"></i> </button>
                            </td>

                            </tr>



                        </>

                        );
                        })): 
                        
                        
                        
                        (
                            <tr>
                                <td colSpan={5}>تحميل...</td>
                            </tr>
                            )}
                    </tbody>
                </table>

                </div>
            </section>


              {/* Modal */}
            {selectedImage && (
                <div className='pay-res-modal' onClick={closeModal}>
                <span className='close-button' onClick={closeModal}>
                    &times;
                </span>
                <div className='pay-res-modal-content'>
                    <img src={selectedImage} alt='Payment Receipt' />
                </div>
                </div>
            )}
                
        </>
    )
}

export default AllGifts;