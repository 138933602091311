import './css/ad.css';

import AdImg from './images/12.webp';
import { useEffect,useState  } from "react";
import axios from '../config/index';

function Ad(){

    const [PhoneNumber, setPhonenumber] = useState([]);

    const getPhonenumber = () => {
        let table = "social";
        let itemId = 8;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setPhonenumber(res.data);
                }
            })
            .catch((err) => console.log(err));
    };


    useEffect(() => {
        getPhonenumber();
    }, []);



    return(
        <>
            <section className='ad'>
                <div className='container'>
                    <div className='ad-content-container'>
                        <div className='row'> 
                            <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                <div className='ad-content'>
                                    <h2>  TakoYaki set for sale </h2>
                                    <h1> <span></span> <span></span> <span></span> 
                                            Sets Us Apart
                                        <span></span> <span></span> <span></span> 
                                    </h1>

                                    <p>
                                        What sets us apart is our dedication to inclusivity. We understand and respect the diverse preferences of our community. Therefore, our Takoyaki is not just delicious, but also adheres to the highest standards of halal. From the savory sauce to the rich soup stock, every element is carefully curated to ensure a delightful experience that caters to everyone.
                                    </p>

                                    <a href={`tel:${PhoneNumber.link}`}>
                                        Contact Us
                                    </a>
                                </div>
                            </div>



                            <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                <div className='ad-content'>
                                    <div className='overlay'></div>
                                    <img src={AdImg} alt="ad image" />
                                </div>
                            </div>






                            <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                <div className='ad-content'>
                                    <h2>  Best collection </h2>
                                    <h1> <span></span> <span></span> <span></span> 
                                            Invitation
                                        <span></span> <span></span> <span></span> 
                                    </h1>

                                    <p>
                                        We invite the people of Egypt to embark on a culinary journey and savor the authentic taste of Takoyaki. Whether you're a fan of Japanese cuisine or new to its delightful offerings, we hope to share a taste of Japan with each and every one of you. Thank you for considering Yamatako as your go-to destination for an unforgettable Japanese street food experience.
                                    </p>

                                    <a href={`tel:${PhoneNumber.link}`}>
                                        Contact Us
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Ad;