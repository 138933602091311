import '../css/map.css';
import React from 'react';

function Map() {

    const currentPath = window.location.pathname;
    const isARLanguage = currentPath.includes('ar');
    const importARStyles = async () => {
        if (isARLanguage) {
        try {
            // Import the AR-specific style sheet
            const arStyles = await import('../css/Ar.css');
            arStyles.default(); // Apply the styles if needed
        } catch (error) {
            console.error('Error importing AR styles:', error);
        }
        }
    };

    React.useEffect(() => {
        importARStyles();
    }, [currentPath]);


    
    return (
        <section className='map'>
            <div className='container'>
                <div className='map-content'>
                    <div className='header'>
                        <h1> <span></span> <span></span> <span></span> 
                                أين تجدنا
                            <span></span> <span></span> <span></span> 
                        </h1>
                        <p>
                            سواء كنت عاشقًا للمأكولات اليابانية أو مبتدئًا حريصًا على استكشاف نكهات جديدة، يعتبر Yamatako الوجهة المثالية. ندعوك لتجربة تاكوياكي لدينا مرة واحدة على الأقل وتذوق النكهات الغنية التي جعلتها جزءًا أساسيًا في ثقافة الطعام الشارعي الياباني.
                        </p>
                    </div>
                    <div className='map-iframe'>
                        <div className='overlay'></div>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3453.1315147985747!2d31.2230851!3d30.061764499999992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145840e6b118b30f%3A0xc4a6a814d1ace162!2zMTMg2LTYp9ix2Lkg2KfZhNio2LHYp9iy2YrZhNiMINmF2K3ZhdivINmF2LjZh9ix2Iwg2KfZhNiy2YXYp9mE2YPYjCDZhdit2KfZgdi42Kkg2KfZhNmC2KfZh9ix2KnigKwgNDI3MTA0NA!5e0!3m2!1sar!2seg!4v1701509918303!5m2!1sar!2seg"
                            width="100%" height="350" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Map;
