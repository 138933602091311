import './css/homemenu.css';

import LongShape from './images/icons/longdecor.svg';
import { Link as RouterLink } from 'react-router-dom';

import React, { useState, useEffect } from "react";
import axios from '../config/index';

function HomeMenu(){


    const [Products, setAllProducts] = useState([]);

    const getAllProducts = () => {
        axios.post("AllItems", {
            table: "products",
        })
        .then((res) => {
            if (res.data) {
                setAllProducts(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };

    useEffect(() => {
        getAllProducts();
    }, []);



    return(
        <>
            <section className='home-menu'>

                <img src={LongShape} alt="menu long shape"  />

                <div className='container'>
                    <div className='home-menu-content'>
                        <div className='row'>

                            <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                <div className='menu-eles'>
                                    <div className='header'>
                                        <h2> Best collection </h2>
                                        <h1> <span></span> <span></span> <span></span> 
                                                YAMATAKO
                                            <span></span> <span></span> <span></span> 
                                        </h1>
                                    </div>

                                    {
                                    Products && Products.length > 0 && (
                                        Products.filter(product => product.place === 'Best Seller').map((product, index) => (
                                            <div key={index} className='ele'>
                                                <h3>{product.name} <span>{product.price} . EGP</span></h3>
                                                <p>{product.para}</p>
                                            </div>
                                        ))
                                        )

                                    }

                                </div>
                            </div>



                            <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                <div className='menu-eles'>
                                    <div className='header'>
                                        <h2> Our Selection </h2>
                                        <h1> <span></span> <span></span> <span></span> 
                                        YAMATAKO
                                            <span></span> <span></span> <span></span> 
                                        </h1>
                                    </div>

                                    {
                                    Products && Products.length > 0 && (

                                        Products.filter(product => product.place === 'Our Choice').map((product, index) => (
                                            <div key={index} className='ele'>
                                                <h3>{product.name} <span>{product.price} . EGP</span></h3>
                                                <p>{product.para}</p>
                                            </div>
                                        ))
                                        )

                                    }


                                </div>
                            </div>



                            
                            <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                <div className='menu-eles'>
                                    <div className='header'>
                                        <h2> Our choise </h2>
                                        <h1> <span></span> <span></span> <span></span> 
                                        YAMATAKO
                                            <span></span> <span></span> <span></span> 
                                        </h1>
                                    </div>

                                    {
                                        Products && Products.length > 0 && (
                                            Products.filter(product => product.place === 'Must Try').map((product, index) => (
                                                <div key={product.id} className='ele'> {/* It's better to use product.id instead of index if available */}
                                                    <h3>{product.name} <span>{product.price} . EGP</span></h3>
                                                    <p>{product.para}</p>
                                                </div>
                                            ))
                                        )
                                    }


                                </div>
                            </div>

                            <div className='col-12'>
                                <div className='read-more'>
                                    <RouterLink to="/our-menu"> READ MENU </RouterLink>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </section>  
        </>
    )
}

export default HomeMenu;