import PageHeader from '../PageHeader';
import '../css/menu.css';

import MealImg1 from '../images/2.webp';
import MealImg2 from '../images/3.webp';
import React, { useState, useEffect } from "react";
import axios from '../../config/index';

import { Link as RouterLink } from 'react-router-dom';

function Menu() {


    
    const [PhoneNumber, setPhonenumber] = useState([]);

    const getPhonenumber = () => {
        let table = "social";
        let itemId = 8;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setPhonenumber(res.data);
                }
            })
            .catch((err) => console.log(err));
    };


    useEffect(() => {
        getPhonenumber();
    }, []);

    
    const [Products, setAllProducts] = useState([]);

    const getAllProducts = () => {
        axios.post("AllItems", {
            table: "products",
        })
        .then((res) => {
            if (res.data) {
                setAllProducts(res.data);
            } else {
                console.log("Error happened");
            }
        })
        .catch((err) => console.log(err));
    };

    useEffect(() => {
        getAllProducts();
    }, []);


    
    const currentPath = window.location.pathname;
    const isARLanguage = currentPath.includes('jp');
    const importARStyles = async () => {
        if (isARLanguage) {
        try {
            // Import the AR-specific style sheet
            const arStyles = await import('../css/Jp.css');
            arStyles.default(); // Apply the styles if needed
        } catch (error) {
            console.error('Error importing AR styles:', error);
        }
        }
    };

    React.useEffect(() => {
        importARStyles();
    }, [currentPath]);


    return (
        <>
            <PageHeader header="メニュー" para="当社のたこ焼きは単なる模倣ではなく、エジプトで愛されている日本のクラシックな味を忠実に再現しています。" />
            <section className='menu-page'>
                <div className='container'>
                    <div className='menu-page-content'>
                        <div className='row'>

                        {
                        Products && Products.length > 0 && (

                            Products.map((product, index) => (
                                
                                <div key={index} className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                    <div className='menu-item'>
                                        <img src={`../uploads/${product.img}`} alt="menu-item-img" />
                                        <div>
                                            <h3> {product.name_ch} <span> {product.price} . EGP </span> </h3>
                                            <p> {product.para_ch} </p>
                                            <a href={`tel:${PhoneNumber.link}`}>
                                            お問い合わせ
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )))
                        }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Menu;
