import '../css/footer.css';
import LongDecoration from '../images/icons/decorverylong.svg';
import React from 'react';

import { useEffect,useState  } from "react";
import axios from '../../config/index';


function Footer() {




    
    
        
    const [location, setLocation] = useState([]);

    const [email_1, setemail_1] = useState([]);
    const [email_2, setemail_2] = useState([]);

    const [working_days_1, setworking_days_1] = useState([]);
    const [working_hours_1, setworking_hours_1] = useState([]);

    const [working_days_2, setworking_days_2] = useState([]);
    const [working_hours_2, setworking_hours_2] = useState([]);


    
    const [facebook, setfacebook] = useState([]);
    const [twitter, settwitter] = useState([]);
    const [instagram, setinstagram] = useState([]);
    const [youtube, setyoutube] = useState([]);
    const [pinterest, setpinterest] = useState([]);

    const getLocation = () => {
        let table = "social";
        let itemId = 14;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setLocation(res.data);
                }
            })
            .catch((err) => console.log(err));
    };


    
    const getEmail1 = () => {
        let table = "social";
        let itemId = 2;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setemail_1(res.data);
                }
            })
            .catch((err) => console.log(err));
    };

    const getEmail2 = () => {
        let table = "social";
        let itemId = 3;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setemail_2(res.data);
                }
            })
            .catch((err) => console.log(err));
    };



    const getWorkingDays1 = () => {
        let table = "social";
        let itemId = 15;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setworking_days_1(res.data);
                }
            })
            .catch((err) => console.log(err));
    };

    const getWorkingHours1 = () => {
        let table = "social";
        let itemId = 16;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setworking_hours_1(res.data);
                }
            })
            .catch((err) => console.log(err));
    };



    
    const getWorkingDays2 = () => {
        let table = "social";
        let itemId = 17;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setworking_days_2(res.data);
                }
            })
            .catch((err) => console.log(err));
    };

    const getWorkingHours2 = () => {
        let table = "social";
        let itemId = 18;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setworking_hours_2(res.data);
                }
            })
            .catch((err) => console.log(err));
    };



    const getfacebook = () => {
        let table = "social";
        let itemId = 9;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setfacebook(res.data);
                }
            })
            .catch((err) => console.log(err));
    };
    
    const gettwitter = () => {
        let table = "social";
        let itemId = 10;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    settwitter(res.data);
                }
            })
            .catch((err) => console.log(err));
    };

    const getinstagram = () => {
        let table = "social";
        let itemId = 11;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setinstagram(res.data);
                }
            })
            .catch((err) => console.log(err));
    };

    const getyoutube = () => {
        let table = "social";
        let itemId = 12;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setyoutube(res.data);
                }
            })
            .catch((err) => console.log(err));
    };

    const getpinterest = () => {
        let table = "social";
        let itemId = 13;

        axios.post('get-item', { itemId, table })
            .then((res) => {
                if (res.data.message) {
                } else {
                    setpinterest(res.data);
                }
            })
            .catch((err) => console.log(err));
    };







    useEffect(() => {
        getLocation();
        getEmail1();
        getEmail2();
        getWorkingDays1();
        getWorkingHours1();
        getWorkingDays2();
        getWorkingHours2();

        getfacebook();
        gettwitter();
        getinstagram();
        getyoutube();
        getpinterest();

    }, []);

    const currentPath = window.location.pathname;
    const isARLanguage = currentPath.includes('ar');
    const importARStyles = async () => {
        if (isARLanguage) {
        try {
            // Import the AR-specific style sheet
            const arStyles = await import('../css/Ar.css');
            arStyles.default(); // Apply the styles if needed
        } catch (error) {
            console.error('Error importing AR styles:', error);
        }
        }
    };

    React.useEffect(() => {
        importARStyles();
    }, [currentPath]);


    
    return (
        <>
            <footer>
                <div className='container'>
                    <div className='footer-content'>
                        <div className='left'>
                            <img src={LongDecoration} alt="صورة تزيين طويلة"/>

                            <a href="https://goo.gl/maps/22LHhRGoQmGn1RdX8">
                                {location.link}
                            </a>

                            <div className='emails'>
                                <a href={`mailto:${email_1.link}`}> {email_1.link} </a>
                                <span> </span>
                                <a href={`mailto:${email_2.link}`}> {email_2.link} </a>
                            </div>

                            <div className='open-hours'>
                                <h4>ساعات العمل:</h4>
                                <h5> <span>{working_days_1.link} </span>  <span> {working_hours_1.link} </span> </h5>
                                <h5> <span>{working_days_2.link} </span>  <span> {working_hours_2.link}</span> </h5>

                            </div>
                        </div>

                        <div className='right'>
                        <a href={facebook.link}>
                                فيسبوك
                            </a>
                            <a href={twitter.link}>
                                تويتر
                            </a>
                            <a href={instagram.link}>
                                إنستغرام
                            </a>
                            <a href={youtube.link}>
                                يوتيوب
                            </a>
                            <a href={pinterest.link}>
                                بينتيريست
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;
